import { useAccount, useChainId } from "wagmi";

const useWalletInfo = () => {
  const { address, isConnected, isDisconnected } = useAccount();
  const chainId = useChainId();

  return {
    address,
    isConnected,
    isDisconnected,
    chainId,
  };
};

export default useWalletInfo;
