// import { ethers } from 'ethers';

// const wagmiDeployContract = async (newData, walletClient) => {
//     try {
//       // Fetch ABI and Bytecode from API
//       const response = await fetch(
//         `https://trendifytokens.io/api/files/getabiandbytecode?tokenname=${newData?.tokenname}&walletaddress=${newData?.walletaddress}&id=${newData?.id}`,
//         { method: 'GET' }
//       );
//       const data = await response.json();

//       // Validate ABI and Bytecode
//       if (!data?.data?.abi || !data?.data?.bytecode) {
//         throw new Error('Failed to retrieve ABI and Bytecode.');
//       }
//       const contractABI = data.data.abi;
//       const contractBytecode = '0x' + data.data.bytecode;

//       // Set up ethers.js provider and signer
//       const provider = new ethers.providers.Web3Provider(walletClient);
//       const signer = provider.getSigner();

//       // Create contract factory and deploy
//       const contractFactory = new ethers.ContractFactory(contractABI, contractBytecode, signer);
//       const contract = await contractFactory.deploy();

//       // Wait for the contract to be mined
//       await contract?.deployTransaction?.wait();

//       // Set contract address and deployment status
//       const address = contract?.address;
//       const deployStatus = true;
//       return [address, deployStatus];
//     } catch (error) {
//       const deployStatus = false;
//       console.error("Error deploying contract:", error);
//       return ["Something went wrong", deployStatus];
//     }
// };

// export default wagmiDeployContract;




import { ethers } from 'ethers';
import { GasEfficiency } from './ContractDependency';

const wagmiDeployContract = async (newData, walletClient) => {
  try {
    // Fetch ABI and Bytecode from API
    const response = await fetch(
      `https://trendifytokens.io/api/files/getabiandbytecode?tokenname=${newData?.tokenname}&walletaddress=${newData?.walletaddress}&id=${newData?.id}`,
      { method: 'GET' }
    );
    const data = await response.json();

    // Validate ABI and Bytecode
    if (!data?.data?.abi || !data?.data?.bytecode) {
      throw new Error('Failed to retrieve ABI and Bytecode.');
    }
    const contractABI = data.data.abi;
    const contractBytecode = '0x' + data.data.bytecode;

    // Set up ethers.js provider and signer
    const provider = new ethers.providers.Web3Provider(walletClient);
    const signer = provider.getSigner();

    // Create contract factory
    const contractFactory = new ethers.ContractFactory(contractABI, contractBytecode, signer);

    // Create contract deployment transaction data
    const deployTransaction = contractFactory.getDeployTransaction();

    // Estimate gas for the deployment using signer.estimateGas
    const gasEstimate = await signer.estimateGas(deployTransaction);

    // Set gas price (optional: set a lower gas price if you want to save cost)
    const gasPrice = await provider.getGasPrice();
    const reducedGasPrice = gasPrice.mul(GasEfficiency).div(100); // Reduce gas price by 10%

    // Deploy contract with estimated gas and reduced gas price
    const contract = await contractFactory.deploy({
      gasLimit: gasEstimate,     // Use estimated gas
      gasPrice: reducedGasPrice, // Use reduced gas price
    });

    // Wait for the contract to be mined
    await contract.deployTransaction.wait();

    // Set contract address and deployment status
    const address = contract?.address;
    const deployStatus = true;
    return [address, deployStatus];
  } catch (error) {
    const deployStatus = false;
    console.error("Error deploying contract:", error);
    return ["Something went wrong", deployStatus];
  }
};

export default wagmiDeployContract;
