// import { http, createConfig } from '@wagmi/core';
// import { mainnet, arbitrum, optimism, polygon, bsc, avalanche, celo, base, fantom, bscTestnet, baseSepolia } from '@wagmi/core/chains';

// // Create the config object
// export const config = createConfig({
//   chains: [
//     mainnet,
//     arbitrum,
//     optimism,
//     polygon,
//     bsc,
//     avalanche,
//     celo,
//     base,
//     fantom,
//     bscTestnet,
//     baseSepolia
//   ],
//   transports: {
//     [mainnet.id]: http(),
//     [arbitrum.id]: http(),
//     [optimism.id]: http(),
//     [polygon.id]: http(),
//     [bsc.id]: http(),
//     [avalanche.id]: http(),
//     [celo.id]: http(),
//     [base.id]: http(),
//     [fantom.id]: http(),
//     [bscTestnet.id]: http(),
//     [baseSepolia.id]: http()
//   },
// });






import { http, createConfig } from '@wagmi/core'
import { mainnet, arbitrum, optimism, polygon, bsc, avalanche, celo, base, fantom, bscTestnet, baseSepolia } from '@wagmi/core/chains'

const chains = [
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  fantom,
  bscTestnet,
  baseSepolia,
]

export const config = createConfig({
  chains,
  transports: chains.reduce((transports, chain) => {
    transports[chain.id] = http();
    return transports;
  }, {}),
});
