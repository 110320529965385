import React, { useEffect, useState } from "react";
import logo from "../Images/Home/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, NavLink } from "react-router-dom";
import "./NavigationBar.css";
import { updateWalletAddress } from "../../src/Store/Slices/GeneralSlice";
import { HiBars3, HiXMark } from "react-icons/hi2";
import { useAccount, useChainId } from "wagmi";
import useClickOutside from "../Hooks/Navigation/useClickOutside";
import { disconnect } from "@wagmi/core";

function NavigationBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { address, isConnected, isDisconnected } = useAccount();
  const chainId = useChainId();
  // const {
  //   data: balanceData,
  //   isError,
  //   isLoading,
  // } = useBalance({
  //   address,
  //   chainId,
  // });

  useEffect(() => {
    // Update localStorage when address or chainId changes
    if (isConnected) {
      localStorage.setItem("walletAddress", address);
      localStorage.setItem("chainID", chainId);
    } else if (isDisconnected) {
      // Clear localStorage when disconnected
      localStorage.removeItem("walletAddress");
      localStorage.removeItem("chainID");
    }
  }, [address, chainId, isConnected, isDisconnected]);

  const sidebarRef = useClickOutside(isSidebarOpen, setIsSidebarOpen);
  const handleSidear = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  console.log(
    "Getting Wallet is Connected or disconnected",
    isConnected,
    isDisconnected
  );

  return (
    <nav className="navbar-container py-3 flex sticky-top">
      <div>
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
      </div>
      {/* NAv for small Devices */}
      <div className="d-lg-none">
        <HiBars3 color="white" size="1.5rem" onClick={handleSidear} />
      </div>
      {isSidebarOpen && (
        <div className="overlay">
          <div className="navbar-sidebar" ref={sidebarRef}>
            <div className="d-flex justify-content-end m-3">
              <HiXMark size="1.5rem" onClick={handleSidear} />
            </div>
            <ul>
              <li>
                <NavLink
                  to="/createtoken"
                  className={({ isActive }) =>
                    `link ${isActive ? "activeLink" : ""}`
                  }
                >
                  Create Token
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    `mx-2 link ${isActive ? "activeLink" : ""}`
                  }
                >
                  Dashboard
                </NavLink>
              </li>
              <div>
                <div className="d-lg-inline-flex align-items-lg-center">
                  <w3m-network-button style={{ marginRight: "1rem" }} />
                  <div className="mt-lg-0 mt-3">
                    <w3m-button balance="hide" />
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      )}

      {/* Nav for Large Devices */}
      <ul className="d-none d-lg-flex justify-content-md-between mt-3">
        <div className="d-flex align-items-center gap-md-3 gap-lg-3">
          <li>
            <NavLink
              to="/createtoken"
              className={({ isActive }) =>
                `link ${isActive ? "activeLink" : ""}`
              }
            >
              Create Token
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                ` mx-3 link ${isActive ? "activeLink" : ""}`
              }
            >
              Dashboard
            </NavLink>
          </li>
          <div>
            <div className="d-lg-inline-flex align-items-lg-center">
              <w3m-network-button style={{ marginRight: "1rem" }} />
              <div className="mt-lg-0 mt-3">
                <w3m-button balance="hide" />
              </div>
            </div>
          </div>
        </div>
      </ul>
    </nav>
  );
}

export default NavigationBar;
