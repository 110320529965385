import React, { useEffect, useRef, useState } from "react";
import CircleIcon from "../../../Images/Logos/Circle.svg";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { truncateText } from "../../../Utils/helperFunction";
import Web3 from "web3";
import { HiMiniCheck } from "react-icons/hi2";
import CopyIcon from "../../../Images/Logos/copy.svg";
import axios from "axios";
import {
  getCurrentTxExplorer,
  getPairAddress,
  getPairAddress1,
  setPairForTaxationEligible,
} from "../../../ContractAction/PaymentContractAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useWriteContract } from "wagmi";
import { usePublicClient, useWalletClient } from "wagmi";
import "./PairAddress.css";

const TokenCard = ({ data }) => {
  const {
    chainId,
    contactaddress,
    tokenname,
    walletaddress,
    id,
    customexchangeinput,
    routeraddress,
    pairaddress,
    defaultexchange,
  } = data;
  console.log("Token Data", data);

  const [copied, setCopied] = useState(false);
  const [extend, setExtended] = useState(false);
  const [userTokenB, setUserTokenB] = useState("");
  console.log("User Input", userTokenB);
  const handleCopy = (pairAddress) => {
    navigator.clipboard.writeText(pairAddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };

  // Note Wagmi Hook Initialization
  let { writeContract, data: pairAddressData } = useWriteContract();

  console.log("Pair Address Data", pairAddressData);

  const goToURL = async (inputType, inputAddress) => {
    let scanUrl;

    switch (chainId) {
      case "1" || "1n" || "0x1": // Polygon Mumbai chain ID
        scanUrl = `https://etherscan.io/${inputType}/${inputAddress}`;
        break;
      case "97" || "97n" || "0x61": // Polygon Mainnet chain ID
        scanUrl = `https://testnet.bscscan.com/${inputType}/${inputAddress}`;
        break;
      case "137" || "137n" || "0x89": // Polygon Mainnet chain ID
        scanUrl = `https://polygonscan.com/${inputType}/${inputAddress}`;
        break;
      case "43114" || "43114n" || "0xa86a": // Avalanche Mainnet chain ID
        scanUrl = `https://43114.snowtrace.io/${inputType}/${inputAddress}`;
        break;
      case "10" || "10n" || "0xa": // Opium Mainnet chain ID
        scanUrl = `https://optimistic.etherscan.io/${inputType}/${inputAddress}`;
        break;
      case "43113" || "43113n" || "0xa869": // Avalanche Testnet chain ID
        scanUrl = `https://43113.testnet.snowtrace.io/${inputType}/${inputAddress}`;
        break;
      case "42161" || "42161n" || "0xa4b1": // Arbitum Testnet chain ID
        scanUrl = `https://arbiscan.io/${inputType}/${inputAddress}`;
        break;
      case "56" || "56n" || "0x38": // BSC Testnet chain ID
        scanUrl = `https://bscscan.com/${inputType}/${inputAddress}`;
        break;
      case "42220" || "42220n" || "0xa4ec": // Celo Testnet chain ID
        scanUrl = `https://celoscan.io/${inputType}/${inputAddress}`;
        break;
      case "8453" || "8453n" || "0x2105": // Base Testnet chain ID
        scanUrl = `https://basescan.org/${inputType}/${inputAddress}`;
        break;
      case "250" || "250n" || "0xfa": // Fantom chain ID
        scanUrl = `https://ftmscan.com/${inputType}/${inputAddress}`;
        break;
      case "84532" || "84532n" || "0x14a34": // Base Sepolia Testnet chain ID
        scanUrl = `https://sepolia.basescan.org/${inputType}/${inputAddress}`;
        break;
      case "421614" || "421614n" || "0x66eee": // Arbitium Sepolia Testnet chain ID
        scanUrl = `https://sepolia.arbiscan.io/${inputType}/${inputAddress}`;
        break;
      default:
        // Handle other chain IDs here
        scanUrl = `https://example.com/${inputType}/${inputAddress}`; // Default URL
    }
    window.open(scanUrl, "_blank");
  };

  // const tokenName = localStorage.getItem("tokenName") || "";
  // const WalletAddress = localStorage.getItem("walletAddress") || "";
  // const ID = localStorage.getItem("ID") || "";
  // Function for download file
  const [buttonText, setButtonText] = useState("DOWNLOAD");
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isPairButtonDisabled, setIsPairButtonDisabled] = useState(false);

  const handleDownload = async () => {
    setButtonText("DOWNLOADING....");
    try {
      const response = await fetch(
        `https://trendifytokens.io/api/files/getdshboarddownloadfiles?tokenname=${tokenname}&walletaddress=${walletaddress}&id=${id}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      // Set the filename with .zip extension
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = `${tokenname}.zip`; // Default filename with .zip extension
      if (contentDisposition && contentDisposition.includes("attachment")) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDisposition
        );
        if (matches != null && matches[1]) {
          filename =
            matches[1].replace(/['"]/g, "").replace(/\.[^/.]+$/, "") + ".zip";
        }
      } else {
        filename = `${tokenname}.zip`; // Fallback filename
      }
      a.download = filename;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setIsDownloaded(true);
      setButtonText("Downloaded");
    } catch (error) {
      setButtonText("Download Failed");
      console.log(error);
    }
  };

  const [pairAddress, setPairAddress] = useState("");
  // const publicClient = useWalletClient()
  const { data: publicClient } = useWalletClient();
  // const provider = useEthersProvider()

  // useEffect(() => {
  //   const pairaddr = async () => {
  //     // const pairAddress = await getPairAddress(contactaddress);
  //     // setPairAddress(pairAddress);
  //     const pairAddress = await getPairAddress1(contactaddress,publicClient);
  //     setPairAddress(pairAddress);
  //   };
  //   pairaddr();
  // }, []);

  useEffect(() => {
    const getPairUsingWagmi = async () => {
      if (publicClient) {
        let responsegetPairAddress1 = await getPairAddress1(
          contactaddress,
          chainId,
          publicClient
        );
        setPairAddress(responsegetPairAddress1);
        console.log("responsegetPairAddress1", responsegetPairAddress1);
      } else {
        console.log("responsegetPairAddress1", "issue");
      }
    };
    getPairUsingWagmi();
  }, [publicClient]);

  const [pairText, setPairText] = useState("Create Pair");

  const postPairAddress = async (pairV2) => {
    try {
      const response = await axios.post(
        "https://trendifytokens.io/api/files/insertpaidaddress",
        {
          id: id,
          pairaddress: pairV2,
        }
      );
      const data = response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handlePairTaxation = async (e) => {
    const txExplorer = await getCurrentTxExplorer(publicClient);
    console.log("Hash in Modal Function", e);
    toast(
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="zeroMargin" style={{ color: "#f1f1f1", fontWeight: 600 }}>
          Pair Created
        </p>
        <a
          href={`${txExplorer}${e}`}
          target="_blank"
          style={{ color: "#f1f1f1" }}
        >
          View on Block Explorer
        </a>
      </div>,
      {
        autoClose: 10000,
        style: {
          background:
            "linear-gradient(224deg, rgba(54, 153, 178, 1) 0%, rgba(16, 207, 163, 1) 94.72%)",
        },
        progressClassName: "toast-progress-bar",
      }
    );
  };

  // Note : Wagmi

  const handleCreatePair = async (tokenAAddress, tokenBAddress) => {
    const abiSetPairForTaxation = [
      {
        inputs: [
          { internalType: "address", name: "tokenB", type: "address" },
          { internalType: "bool", name: "isExcluded", type: "bool" },
        ],
        name: "setPairForTaxation",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    try {
      const response = await writeContract({
        abi: abiSetPairForTaxation,
        address: tokenAAddress, // Token A (the user's token)
        functionName: "setPairForTaxation",
        args: [tokenBAddress, true], // Token B and exclusion flag
      });
      console.log("Pair set for taxation successfully:", response);
      return response;
    } catch (error) {
      console.error("Failed to set pair for taxation:", error);
    }
  };

  const handlePairForTaxationEligible = async () => {
    try {
      await handleCreatePair(contactaddress, userTokenB);
      console.log("pairCreatedTaxation3:");
      // if (pairCreatedTaxation.transactionHash) {
      //   handlePairTaxation(pairCreatedTaxation.transactionHash);
      //   setExtended(false);
      //   setUserTokenB("");
      // }
    } catch (error) {
      console.log(error);
      if (error) {
        toast("Transaction Failed", {
          autoClose: 10000,
          style: {
            background:
              "linear-gradient(224deg, rgba(54, 153, 178, 1) 0%, rgba(16, 207, 163, 1) 94.72%)",
          },
          progressClassName: "toast-progress-bar",
        });
      }
    }
  };

  // console.log("Pair Address", pairAddress);
  useEffect(() => {
    if (pairaddress) {
      setIsPairButtonDisabled(true);
    }
  }, [pairaddress]);

  useEffect(() => {
    const showPairAddressToast = async () => {
      if (pairAddressData) {
        console.log("Pair Address Data detected:", pairAddressData); // Add logging
        await handlePairTaxation(pairAddressData);
        setExtended(false);
        setUserTokenB("");
      }
    };
    if (pairAddressData) {
      console.log("useWriteContract pairAddressData:", pairAddressData);
      showPairAddressToast();
    }
  }, [pairAddressData]);

  // Hide the Pair Address when click outSide
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setExtended(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Col
      sm={12}
      md={8}
      lg={5}
      className="my-3 p-4"
      style={{
        borderRadius: "16px",
        background: "rgba(255, 255, 255, 0.10)",
      }}
    >
      {/* Token Header */}
      <div className="mb-3">
        <div className="d-flex align-items-center gap-2 mb-4">
          <div>
            <img src={CircleIcon} alt="circleicon" />
          </div>
          <p
            className="zeroMargin fs-5"
            style={{ color: "#FFF", wordBreak: "break-word" }}
          >
            {data?.tokenname} ({data?.tokensymbol})
          </p>
        </div>
        <div className="d-flex item-center justify-content-between">
          <p className="zeroMargin" style={{ color: "#D7D7D7" }}>
            Token address
          </p>
          <p
            onClick={() => goToURL("token", data?.contactaddress)}
            className="zeroMargin"
            style={{ color: "#F1F1F1", cursor: "pointer" }}
          >
            {truncateText(data?.contactaddress)}
          </p>
        </div>
        <div className="d-flex item-center justify-content-between my-1">
          <p className="zeroMargin" style={{ color: "#D7D7D7" }}>
            Deployer
          </p>
          <p
            onClick={() => goToURL("address", data?.walletaddress)}
            className="zeroMargin"
            style={{ color: "#F1F1F1", cursor: "pointer" }}
          >
            {truncateText(data?.walletaddress)}
          </p>
        </div>
        <div className="d-flex item-center justify-content-between">
          <p className="zeroMargin" style={{ color: "#D7D7D7" }}>
            Owner
          </p>

          {data?.tokenowneraddress ? (
            <p
              onClick={() => goToURL("address", data?.tokenowneraddress)}
              className="zeroMargin"
              style={{ color: "#F1F1F1", cursor: "pointer" }}
            >
              {truncateText(data?.tokenowneraddress)}
            </p>
          ) : (
            <p
              onClick={() => goToURL("address", data?.walletaddress)}
              className="zeroMargin"
              style={{ color: "#F1F1F1", cursor: "pointer" }}
            >
              {truncateText(data?.walletaddress)}
            </p>
          )}
        </div>

        {/* Working Pair Address */}
        {/* {pairAddress !== "0x0000000000000000000000000000000000000000" ? (
          <div className="d-flex item-center justify-content-between">
            <div
              className="d-flex align-items-center gap-3"
              style={{ position: "relative" }}
            >
              <p
                className="zeroMargin"
                style={{ color: "#D7D7D7", marginBottom: 0 }}
              >
                Pair Address
              </p>
              <button
                style={{
                  position: "absolute",
                  top: 0,
                  right: -20,
                  padding: "4px",
                  border: "none",
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  backgroundColor: "#f0f0f0", // Optional: Add a background color
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setExtended(!extend)}
              >
                +
              </button>
              {extend && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px", // Add some space between elements
                    position: "absolute",
                    top: 0,
                    left: 112, // Adjust this value to position correctly
                    backgroundColor: "#17171B",
                    padding: "4px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional: Add some shadow
                    borderRadius: "4px", // Optional: Round the corners
                  }}
                >
                  <input
                    type="text"
                    value={userTokenB}
                    onChange={(e) => setUserTokenB(e.target.value)}
                    placeholder="Address To Create Pair"
                    style={{
                      padding: "2px 5px",
                      width: "160px",
                      outline: "none",
                      border: "1px solid gray",
                      color: "white",
                      background: "transparent",
                    }} // Adjust width as needed
                  />
                  <button
                    onClick={handlePairForTaxationEligible}
                    style={{ fontSize: "10px" }}
                    className="text-decoration-none px-2 text-center py-1 btn-bg-color text-light border-0 rounded-2"
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
            <p
              onClick={() => goToURL("token", pairAddress)}
              className="zeroMargin"
              style={{ color: "#F1F1F1", cursor: "pointer" }}
            >
              {truncateText(pairAddress)}
            </p>
          </div>
        ) : (
          <div className="d-flex item-center justify-content-between">
            <p className="zeroMargin" style={{ color: "#D7D7D7" }}>
              Pair Address
            </p>
            <p
              className="zeroMargin"
              style={{ color: "#F1F1F1", cursor: "pointer" }}
            >
              -
            </p>
          </div>
        )} */}
        <div ref={containerRef}>
          {pairAddress !== "0x0000000000000000000000000000000000000000" ? (
            <div className="pair-container">
              <div className="d-flex align-items-center gap-3">
                <p className="pair-label">Pair Address</p>
                <button
                  className="expand-button"
                  onClick={() => setExtended(!extend)}
                >
                  +
                </button>
                {extend && (
                  <div className="extended-input">
                    <input
                      type="text"
                      value={userTokenB}
                      onChange={(e) => setUserTokenB(e.target.value)}
                      placeholder="Address To Create Pair"
                      className="input-address"
                    />
                    <button
                      onClick={handlePairForTaxationEligible}
                      className="submit-button text-decoration-none px-2 text-center py-1 btn-bg-color text-light border-0 rounded-2"
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
              <p
                onClick={() => goToURL("token", pairAddress)}
                className="pair-address"
              >
                {truncateText(pairAddress)}
              </p>
            </div>
          ) : (
            <div className="pair-container">
              <p className="pair-label">Pair Address</p>
              <p className="pair-address">-</p>
            </div>
          )}
        </div>

        {/* {pairaddress && (
          <div className="d-flex item-center justify-content-between">
            <p className="zeroMargin" style={{ color: "#D7D7D7" }}>
              Pair
            </p>
            <div className="d-flex item-center gap-2">
              <p style={{ color: "#F1F1F1" }}>
                {truncateText(pairaddress)}
              </p>
              {copied ? (
                <HiMiniCheck color="white" size="1.2rem" />
              ) : (
                <img
                  src={CopyIcon}
                  alt="copyIcon"
                  onClick={() => handleCopy(pairaddress)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
          </div>
        )} */}
      </div>
      {/* <Link
        to={`/dashboard/manage/${data?.contactaddress}`}
        className="text-decoration-none px-5 text-center py-2 btn-bg-color mt-4"
        style={{ color: "#F1F1F1", display: "block", borderRadius: "8px" }}
      >
        Manage Token
      </Link> */}
      <div className="d-flex flex-column flex-md-row gap-2">
        {chainId !== null && (
          <button
            className="text-decoration-none px-4 text-center py-2 btn-bg-color mt-4 text-light border-0 rounded-2 w-100"
            onClick={() => goToURL("token", data?.contactaddress)}
          >
            View On Explorer
          </button>
        )}
        {/* <button className="text-decoration-none px-4 text-center py-2 btn-bg-color mt-4 text-light border-0 rounded-2 w-100">
          Verify
        </button> */}

        <button
          className="text-decoration-none px-4 text-center py-2 btn-bg-color mt-4 text-light border-0 rounded-2 w-100"
          style={{
            background: `${
              buttonText === "DOWNLOADING FOR AUDIT" ? "#17171B" : ""
            }`,
            opacity: `${buttonText === "DOWNLOADING FOR AUDIT" ? "0.5" : "1"}`,
          }}
          onClick={handleDownload}
        >
          {buttonText}
        </button>
      </div>
      {/* <ToastContainer /> */}
    </Col>
  );
};

export default TokenCard;
