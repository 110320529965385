import Web3 from "web3";
import { abiUSD } from "./ABI/USD.js";
import { ethers } from "ethers";
import {
  detectCurrentProvider,
  AdminPaymentAddress,
  TokenPayAmount,
  getRPCProvider,
} from "./ContractDependency.js";

import { getWalletClient } from "@wagmi/core";
import { providers, utils } from "ethers";
const chainAddresses = require("./ChainAddresses.json");

var abiUSDToken = abiUSD;

export const deployContract = async (newData) => {
  //LESS GAS USAGE
  // setDeployText("DEPLOYING...");
  try {
    if (!window.ethereum) {
      throw new Error("MetaMask or Web3 provider is not available.");
    }
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    const userAddress = accounts[0];
    console.log("Connected wallet address:", userAddress);

    const response = await fetch(
      `https://trendifytokens.io/api/files/getabiandbytecode?tokenname=${newData?.tokenname}&walletaddress=${newData?.walletaddress}&id=${newData?.id}`,
      { method: "GET" }
    );
    // Parse the response as JSON to get ABI and Bytecode
    const data = await response.json();

    // Validate that we have ABI and Bytecode
    if (!data?.data?.abi || !data?.data?.bytecode) {
      throw new Error("Failed to retrieve ABI and Bytecode.");
    }
    const contractABI = data.data.abi;
    const contractBytecode = "0x" + data.data.bytecode;

    console.log(contractABI, "contractabi");
    console.log("bytecode", contractBytecode);
    const contract = new web3.eth.Contract(contractABI);

    // No constructor arguments are needed
    const constructorArguments = [];

    console.log("Constructor arguments:", constructorArguments);

    // Deploy the contract with the retrieved ABI and Bytecode
    const estimatedGasLimit = await contract
      .deploy({
        data: contractBytecode,
        arguments: constructorArguments,
      })
      .estimateGas({ from: userAddress });

    // Set a lower gas price based on current network conditions
    let gasPrice = await web3.eth.getGasPrice();
    gasPrice = web3.utils.toWei("2", "gwei"); // Example: Lowering to 2 Gwei

    const gasLimitCap = 9000000n; // Use BigInt for gas limit cap
    const finalGasLimit =
      estimatedGasLimit < gasLimitCap ? estimatedGasLimit : gasLimitCap;

    const deployedContract = await contract
      .deploy({
        data: contractBytecode,
        arguments: constructorArguments,
      })
      .send({
        from: userAddress,
        gas: estimatedGasLimit, // Use the finalGasLimit
        gasPrice: gasPrice,
      });
    // Store the contract address and reset any error
    const address = deployedContract.options.address;
    const txHash = deployedContract.transactionHash;
    console.log("Deployed Contract Transaction Hash:", txHash);
    console.log("Deployed Contract Address:", address);

    const deployStatus = true;
    return [address, deployStatus];
  } catch (error) {
    const deployStatus = false;
    console.error("Error deploying contract:", error);
    return ["Something went wrong", deployStatus];
  }
};

export const wagmiDeployContractData = async (newData) => {
  try {
    const response = await fetch(
      `https://trendifytokens.io/api/files/getabiandbytecode?tokenname=${newData?.tokenname}&walletaddress=${newData?.walletaddress}&id=${newData?.id}`,
      { method: "GET" }
    );
    const data = await response.json();
    if (!data?.data?.abi || !data?.data?.bytecode) {
      throw new Error("Failed to retrieve ABI and Bytecode.");
    }
    const contractABI = data.data.abi;
    const contractBytecode = "0x" + data.data.bytecode;
    console.log("contractabibytecode".contractABI, contractBytecode);
    return [contractABI, contractBytecode];
  } catch (error) {
    console.error("Error deploying contract:", error);
  }
};

// export const deployContract = async (newData) => {//MORE GAS USAGE
//   if (!window.ethereum) {
//     console.error("MetaMask or Web3 provider is not available.");
//     return;
//   }
//   const web3 = new Web3(window.ethereum);
//   let userAddress;
//   try {
//     const accounts = await web3.eth.getAccounts();
//     userAddress = accounts.length > 0 ? accounts[0] : null;
//     if (userAddress) {
//       console.log("Connected wallet address:", userAddress);
//     } else {
//       console.error("No accounts found.");
//       return;
//     }
//     const response = await fetch(
//       `https://trendifytokens.io/api/files/getabiandbytecode?tokenname=${newData?.tokenname}&walletaddress=${newData?.walletaddress}&id=${newData?.id}`
//     );
//     if (!response.ok) {
//       console.error("Failed to fetch ABI and Bytecode.");
//     }
//     const data = await response.json();
//     const { abi, bytecode } = data?.data || {};
//     if (!abi || !bytecode) {
//       console.error("Failed to retrieve ABI and Bytecode.");
//       return;
//     }
//     const contract = new web3.eth.Contract(abi);
//     const deployedContract = await contract
//       .deploy({
//         data: "0x" + bytecode,
//         arguments: [],
//       })
//       .send({ from: userAddress });
//     if (deployedContract) {
//       console.log(
//         "Deployed Contract Transaction Hash:",
//         deployedContract.transactionHash
//       );
//       console.log(
//         "Deployed Contract Address:",
//         deployedContract.options.address
//       );
//     } else {
//       console.error("Contract deployment failed.");
//     }
//     return [deployedContract.options.address, true];
//   } catch (error) {
//     console.error("Error during contract deployment:", error.message);
//     return ["Something went wrong", false];
//   }
// };

export const getPairAddress = async (routerAddr) => {
  const abi = [
    {
      inputs: [],
      name: "pairV2",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
  ];
  try {
    const web3 = new Web3(window.ethereum);
    const contractInstance = new web3.eth.Contract(abi, routerAddr);
    const pairV2 = await contractInstance.methods.pairV2().call();
    if (
      web3.utils.isAddress(pairV2) &&
      pairV2 !== "0x0000000000000000000000000000000000000000"
    ) {
      console.log(`PairV2: ${pairV2}, for Router: ${routerAddr}`);
      return pairV2;
    }
  } catch (error) {
    console.error(`Error fetching pairV2 for Router: ${routerAddr}`, error);
  }
  console.log(`Returning zero address for Router: ${routerAddr}`);
  return "0x0000000000000000000000000000000000000000";
};

export const getPairAddress1 = async (routerAddr, pairAddrChainId, publicClient) => {
  const abi = [
    {
      inputs: [],
      name: "pairV2",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
  ];
  try {
    const provider = new ethers.providers.Web3Provider(publicClient);
    const currentChain = await getCurrentChainId1(publicClient);
    const rpcUrl = currentChain == pairAddrChainId ? null : await getRPCProvider(pairAddrChainId);
    const activeProvider = rpcUrl ? new ethers.providers.JsonRpcProvider(rpcUrl) : provider;
    console.log("activeProvider",activeProvider,rpcUrl,currentChain)
    if (activeProvider) {
      const contractInstance = new ethers.Contract(routerAddr, abi, activeProvider);
      const pairV2 = await contractInstance.pairV2();
      if (ethers.utils.isAddress(pairV2) && pairV2 !== "0x0000000000000000000000000000000000000000") {
        console.log(`PairV2: ${pairV2}, for Router: ${routerAddr}${rpcUrl ? ` on chain ID: ${pairAddrChainId}` : ''}`);
        return pairV2;
      }
    } else {
      console.error(`No RPC URL found for chain ID: ${pairAddrChainId}`);
    }
  } catch (error) {
    console.error(`Error fetching pairV2 for Router: ${routerAddr}`, error);
  }
  console.log(`Returning zero address for Router: ${routerAddr}`);
  return "0x0000000000000000000000000000000000000000";
};

export const getCurrentChainId = async () => {
  console.log("getCurrentChainId");
  try {
    const provider = detectCurrentProvider();
    if (!provider) {
      throw new Error(
        "No Ethereum provider found. Please install MetaMask or Trust Wallet."
      );
    }
    const web3 = new Web3(provider);
    const CurrentChainId = Number(await web3.eth.net.getId());
    console.log("CurrentChainId", CurrentChainId);
    return CurrentChainId;
  } catch (error) {
    console.log("getCurrentChainIdERROR", error);
  }
};
getCurrentChainId();

export const getCurrentChainId1 = async (provider) => {
  console.log("getCurrentChainId");
  try {
    if (!provider) {
      throw new Error("No Ethereum provider found. Please install MetaMask or Trust Wallet.");
    }
    const ethersProvider = new ethers.providers.Web3Provider(provider);
    const network = await ethersProvider.getNetwork();
    const CurrentChainId = network.chainId;
    console.log("CurrentChainId", CurrentChainId);
    return CurrentChainId;
  } catch (error) {
    console.error("getCurrentChainIdERROR", error);
    return null;
  }
};
getCurrentChainId1();


export const getCurrentChainIdAllDetails = async () => {
  try {
    let chainId = await getCurrentChainId();
    const chain = chainAddresses.find((chain) => chain.hasOwnProperty(chainId));
    if (chain) {
      const allTokens = chain[chainId].map((token) => ({
        stableCoinAddress: token.StableCoinAddress,
        stableCoinName: token.StableCoinName,
        name: token.name,
        routerAddress: token.Routeraddress,
        dexLink: token.Dexlink,
        explorerLink: token.explorerlink,
      }));
      console.log("getCurrentChainIdAllDetails:", allTokens);
      return allTokens;
    } else {
      console.log(`Chain ID ${chainId} not found`);
      return [];
    }
  } catch (error) {
    console.log("Error_getCurrentChainIdAllDetails:", error);
    return [];
  }
};
getCurrentChainIdAllDetails();

export const getCurrentChainIdStableCoinAddress = async (chainId) => {
  console.log("getCurrentChainIdStableCoinAddress");
  const chainDetails = chainAddresses.find((chain) =>
    chain.hasOwnProperty(chainId)
  );
  console.log("chainDetails", chainDetails);
  if (chainDetails) {
    const stableCoinAddress = chainDetails[chainId].find(
      (token) => token.StableCoinAddress !== undefined
    );
    if (stableCoinAddress) {
      console.log(
        `getCurrentChainIdStableCoinAddress on ${chainId}: `,
        stableCoinAddress.StableCoinAddress
      );
      return stableCoinAddress.StableCoinAddress;
    } else {
      console.log(`No stable coin address found for chain ID ${chainId}`);
      return null;
    }
  } else {
    console.log(`Chain ID ${chainId} not found`);
    return null;
  }
};

export const getTokenBalance = async (tokenContractAddress) => {
  console.log("getTokenBalance");
  try {
    const provider = detectCurrentProvider();
    if (!provider) {
      throw new Error(
        "No Ethereum provider found. Please install MetaMask or Trust Wallet."
      );
    }
    const web3 = new Web3(provider);
    const accounts = await provider.request({
      method: "eth_accounts",
    });
    const account = accounts[0];
    const contracts = new web3.eth.Contract(abiUSDToken, tokenContractAddress);
    console.log("contracts_methods", contracts.methods);
    console.log("account", account);
    const response = await contracts.methods.balanceOf(account).call();
    const tokenDecimals = await contracts.methods.decimals().call();
    console.log("tokenDecimals", tokenDecimals);
    const balance = Number(response) / 10 ** Number(tokenDecimals);
    console.log(`TokenBalance of ${tokenContractAddress}`, balance);
    return balance;
  } catch (error) {
    console.log("balance_error", error);
  }
};

export const getTokenSymbol = async (tokenContractAddress) => {
  try {
    const provider = detectCurrentProvider();
    if (!provider) {
      throw new Error(
        "No Ethereum provider found. Please install MetaMask or Trust Wallet."
      );
    }
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(abiUSDToken, tokenContractAddress);
    const tokenSymbol = await contract.methods.symbol().call();
    console.log(
      `Token Symbol of address ${tokenContractAddress} is: ${tokenSymbol}`
    );
    return tokenSymbol;
  } catch (error) {
    console.log(
      `Error fetching token symbol for address ${tokenContractAddress}:`,
      error
    );
  }
};

export const getNetworkRPC = async (chainId) => {
  try {
    const networks = {
      1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      11155111: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      56: "https://bsc-dataseed.binance.org/",
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      42161: "https://arb1.arbitrum.io/rpc",
      421614: "https://rinkeby.arbitrum.io/rpc",
      137: "https://polygon-rpc.com/",
      80002: "https://rpc-mumbai.maticvigil.com/",
      43114: "https://api.avax.network/ext/bc/C/rpc",
      43113: "https://api.avax-test.network/ext/bc/C/rpc",
      10: "https://mainnet.optimism.io",
      69: "https://kovan.optimism.io",
      8453: "https://bsc-dataseed.binance.org/",
      84532: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    };
    const networkRPC = networks[chainId];
    if (!networkRPC) {
      console.error("Network not found for the current chain ID.");
      return null;
    }
    return networkRPC;
  } catch (error) {
    console.error("An error occurred while fetching network info:", error);
    return null;
  }
};

export const getCurrentOwner = async (tokenContractAddress, chainId) => {
  try {
    const provider = detectCurrentProvider();
    if (!provider) {
      throw new Error(
        "No Ethereum provider found. Please install MetaMask or Trust Wallet."
      );
    }
    const rpcUrl = await getNetworkRPC(chainId);
    let web3;
    if (rpcUrl) {
      web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
    } else {
      console.error("Failed to create Web3 instance: Invalid RPC URL.");
    }
    const contract = new web3.eth.Contract(abiUSDToken, tokenContractAddress);
    const currentOwner = await contract.methods.owner().call();
    console.log(`Owner of address ${tokenContractAddress} is: ${currentOwner}`);
    const walletAddress = await getCurrentConnectedAddress();
    const isCurrentOwer =
      walletAddress.toLowerCase() == currentOwner.toLowerCase();
    console.log(
      "isCurrentOwer",
      "getCurrentOwner",
      isCurrentOwer,
      currentOwner
    );
    return [isCurrentOwer, currentOwner];
  } catch (error) {
    console.error(
      `Error fetching owner for address ${tokenContractAddress}:`,
      error
    );
  }
};
getCurrentOwner("0xb6a9ef47ea41d4d5378cee779b4332a9412cb32c", "97");

// export const setPairForTaxationEligible = async (
//   tokenContractAddress,
//   targetChainId,
//   tokenB
// ) => {
//   if (!window.ethereum) {
//     console.error("MetaMask is not installed. Please install MetaMask.");
//     return;
//   }
//   try {
//     await window.ethereum.request({ method: "eth_requestAccounts" });
//     const provider = detectCurrentProvider();
//     const web3 = new Web3(provider);
//     const abiPairCreation = [
//       {
//         inputs: [
//           {
//             internalType: "address",
//             name: "tokenB",
//             type: "address",
//           },
//           {
//             internalType: "bool",
//             name: "isExcluded",
//             type: "bool",
//           },
//         ],
//         name: "setPairForTaxation",
//         outputs: [
//           {
//             internalType: "address",
//             name: "",
//             type: "address",
//           },
//         ],
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//     ];
//     const contract = new web3.eth.Contract(
//       abiPairCreation,
//       tokenContractAddress
//     );
//     const currentChainId = await web3.eth.getChainId();
//     if (currentChainId != targetChainId) {
//       try {
//         await window.ethereum.request({
//           method: "wallet_switchEthereumChain",
//           params: [{ chainId: Web3.utils.toHex(targetChainId) }],
//         });
//       } catch (switchError) {
//         if (switchError.code === 4902) {
//           throw new Error(
//             `Chain ID ${targetChainId} is not added to MetaMask.`
//           );
//         }
//         throw new Error(
//           `Error switching to chain ID ${targetChainId}: ${switchError.message}`
//         );
//       }
//     }
//     const pairAddress = await contract.methods
//       .setPairForTaxation(tokenB, true)
//       .send({ from: provider.selectedAddress });
//     console.log(`Pair set for taxation: ${pairAddress}`);
//     return pairAddress;
//   } catch (error) {
//     console.error(
//       `Error setting pair for taxation for address ${tokenContractAddress}:`,
//       error
//     );
//   }
// };

// Function to convert WalletClient to ethers.js Signer
export function walletClientToSigner(walletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

// Function to get ethers.js Signer using wagmi
export async function getEthersSigner({ chainId } = {}) {
  console.log("getEthersSigner_walletClient1");
  const walletClient = await getWalletClient({ chainId });
  console.log("getEthersSigner_walletClient", walletClient);
  if (!walletClient) return undefined;
  return walletClientToSigner(walletClient);
}

// Refactored setPairForTaxationEligible function
export const setPairForTaxationEligible = async (
  tokenContractAddress,
  targetChainId,
  tokenB
) => {
  console.log(
    "Pair Address Function Args",
    tokenContractAddress,
    targetChainId,
    tokenB
  );

  try {
    // Get the signer using wagmi and ethers.js
    console.log("Pair Address Step 1");
    const signer = await getEthersSigner({ chainId: targetChainId });
    if (!signer) {
      console.error("Wallet not connected or signer unavailable.");
      return;
    }

    console.log("Pair Address Step 2");

    // ABI for the function setPairForTaxation
    const abiPairCreation = [
      {
        inputs: [
          { internalType: "address", name: "tokenB", type: "address" },
          { internalType: "bool", name: "isExcluded", type: "bool" },
        ],
        name: "setPairForTaxation",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    // Initialize ethers.js contract
    const contract = new ethers.Contract(
      tokenContractAddress,
      abiPairCreation,
      signer
    );

    // Get the current chain ID from the signer provider
    const currentChainId = (await signer.provider.getNetwork()).chainId;

    // If the current chain doesn't match the target, switch chains
    if (currentChainId !== targetChainId) {
      console.error(
        `Wrong network. Please switch to the target chain ID: ${targetChainId}`
      );
      return;
    }

    // Call the contract method to setPairForTaxation
    const tx = await contract.setPairForTaxation(tokenB, true);

    // Wait for the transaction to be mined
    const receipt = await tx.wait();
    console.log(
      `Pair set for taxation, transaction hash: ${receipt.transactionHash}`
    );

    return receipt;
  } catch (error) {
    console.error(
      `Error setting pair for taxation for address ${tokenContractAddress}:`,
      error
    );
  }
};

export const getCurrentTxExplorer = async (provider) => {
  const currentChainId = await getCurrentChainId1(provider);
  console.log("getCurrentTxExplorerCurrentChainId", currentChainId);
  const currentChainIdInHex = ethers.BigNumber.from(currentChainId).toHexString();
  const networkExplorers = [
    {
      key: 1,
      networkExplorer: "https://etherscan.io/tx/",
      chainId: "0x1",
    },
    {
      key: 2,
      networkExplorer: "https://bscscan.com/tx/",
      chainId: "0x38",
    },
    {
      key: 3,
      networkExplorer: "https://polygonscan.com/tx/",
      chainId: "0x89",
    },
    {
      key: 4,
      networkExplorer: "https://snowscan.xyz/tx/",
      chainId: "0xa86a",
    },
    {
      key: 5,
      networkExplorer: "https://arbiscan.io/tx/",
      chainId: "0xa4b1",
    },
    {
      key: 6,
      networkExplorer: "https://optimistic.etherscan.io/tx/",
      chainId: "0xa",
    },
    {
      key: 7,
      networkExplorer: "https://basescan.org/tx/",
      chainId: "0x2105",
    },
    {
      key: 8,
      networkExplorer: "https://explorer.celo.org/mainnet/tx/",
      chainId: "0xa4ec",
    },
    {
      key: 9,
      networkExplorer: "https://sepolia.etherscan.io/tx/",
      chainId: "0xaa36a7",
    },
    {
      key: 10,
      networkExplorer: "https://testnet.bscscan.com/tx/",
      chainId: "0x61",
    },
    {
      key: 11,
      networkExplorer: "https://amoy.polygonscan.com/tx/",
      chainId: "0x13882",
    },
    {
      key: 12,
      networkExplorer: "https://testnet.snowtrace.io/tx/",
      chainId: "0xa869",
    },
    {
      key: 13,
      networkExplorer: "https://goerli.basescan.org/tx/",
      chainId: "0x2105",
    },

    {
      key: 14,
      networkExplorer: "https://goerli-optimism.etherscan.io/tx/",
      chainId: "0x1a4",
    },
    {
      key: 15,
      networkExplorer: "https://alfajores.celoscan.io/tx/",
      chainId: "0xaef3",
    },
    {
      key: 16,
      networkExplorer: "https://sepolia.basescan.org/tx/",
      chainId: "0x14a34",
    },
    {
      key: 17,
      networkExplorer: "https://ftmscan.com/tx/",
      chainId: "0xfa",
    },
  ];
  const currentNetworkExplorer = networkExplorers.find(
    (explorer) =>
      explorer.chainId.toLowerCase() === currentChainIdInHex.toLowerCase()
  );
  console.log(
    "currentNetworkTXNExplorer",
    currentNetworkExplorer.networkExplorer
  );
  return currentNetworkExplorer ? currentNetworkExplorer.networkExplorer : null;
};

export const getImportedAddressExplorer = async () => {
  const currentChainId = await getCurrentChainId();
  console.log("getCurrentTxExplorerCurrentChainId", currentChainId);
  const currentChainIdInHex = Web3.utils.toHex(currentChainId);
  const networkExplorers = [
    {
      key: 1,
      networkExplorer: "https://etherscan.io/address/",
      chainId: "0x1",
    },
    {
      key: 2,
      networkExplorer: "https://bscscan.com/address/",
      chainId: "0x38",
    },
    {
      key: 3,
      networkExplorer: "https://polygonscan.com/address/",
      chainId: "0x89",
    },
    {
      key: 4,
      networkExplorer: "https://snowscan.xyz/address/",
      chainId: "0xa86a",
    },
    {
      key: 5,
      networkExplorer: "https://arbiscan.io/address/",
      chainId: "0xa4b1",
    },
    {
      key: 6,
      networkExplorer: "https://optimistic.etherscan.io/address/",
      chainId: "0xa",
    },
    {
      key: 7,
      networkExplorer: "https://basescan.org/address/",
      chainId: "0x2105",
    },
    {
      key: 8,
      networkExplorer: "https://explorer.celo.org/mainnet/address/",
      chainId: "0xa4ec",
    },

    {
      key: 9,
      networkExplorer: "https://sepolia.etherscan.io/address/",
      chainId: "0xaa36a7",
    },
    {
      key: 10,
      networkExplorer: "https://testnet.bscscan.com/address/",
      chainId: "0x61",
    },
    {
      key: 11,
      networkExplorer: "https://amoy.polygonscan.com/address/",
      chainId: "0x13882",
    },
    {
      key: 12,
      networkExplorer: "https://testnet.snowtrace.io/address/",
      chainId: "0xa869",
    },
    {
      key: 13,
      networkExplorer: "https://goerli.basescan.org/address/",
      chainId: "0x2105",
    },

    {
      key: 14,
      networkExplorer: "https://goerli-optimism.etherscan.io/address/",
      chainId: "0x1a4",
    },
    {
      key: 15,
      networkExplorer: "https://alfajores.celoscan.io/address/",
      chainId: "0xaef3",
    },
    {
      key: 16,
      networkExplorer: "https://sepolia.basescan.org/address/",
      chainId: "0x14a34",
    },
    {
      key: 17,
      networkExplorer: "https://ftmscan.com/address/",
      chainId: "0xfa",
    },
  ];
  const currentNetworkExplorer = networkExplorers.find(
    (explorer) =>
      explorer.chainId.toLowerCase() === currentChainIdInHex.toLowerCase()
  );
  console.log(
    "currentNetworkADDRExplorer",
    currentNetworkExplorer.networkExplorer
  );
  return currentNetworkExplorer ? currentNetworkExplorer.networkExplorer : null;
};
getImportedAddressExplorer();

// export const transferPayment = async () => {
//   try {
//     const provider = detectCurrentProvider();
//     if (!provider) {
//       console.error("Noproviderdetected");
//     }
//     console.log("Providerdetected:", provider);
//     const web3 = new Web3(provider);
//     await provider.request({
//       method: "eth_requestAccounts",
//     });
//     const accounts = await provider.request({
//       method: "eth_accounts",
//     });
//     const account = accounts[0];
//     console.log("Accountfound:", account);
//     const currentChainId = await getCurrentChainId();
//     if (!currentChainId) {
//       console.error("UnableToRetrieveCurrentChainID");
//     }
//     console.log("CurrentChainID:", currentChainId);
//     const paymentTokenAddress = await getCurrentChainIdStableCoinAddress(
//       currentChainId
//     );
//     if (!paymentTokenAddress) {
//       console.error("UnableToRetrieveStablecoinAddress_chainID");
//     }
//     console.log("PaymentTokenAddress:", paymentTokenAddress);
//     const contracts = new web3.eth.Contract(abiUSDToken, paymentTokenAddress);
//     console.log("contracts", contracts);
//     const tokenPayDecimals = await contracts.methods.decimals().call();
//     console.log("tokenPayDecimals", tokenPayDecimals);
//     const tokenPayAmount = (
//       Number(TokenPayAmount) *
//       10 ** Number(tokenPayDecimals)
//     ).toString();
//     console.log("tokenPayAmount", tokenPayAmount);
//     const resTransferPayment = await contracts.methods
//       .transfer(AdminPaymentAddress, tokenPayAmount)
//       .send({
//         from: account,
//       });
//     console.log("TransferSuccessful:", resTransferPayment);
//     const txExplorer = await getCurrentTxExplorer();
//     const txHashResponse = {
//       txHash: txExplorer + resTransferPayment.transactionHash,
//       status: resTransferPayment.status,
//     };
//     console.log("txHashResponse", txHashResponse);
//     return txHashResponse;
//   } catch (error) {
//     console.error("Error_TransferPayment:", error);
//   }
// };

export const getCurrentConnectedAddress = async () => {
  const provider = detectCurrentProvider();
  if (!provider) {
    console.error("No provider detected");
    return null;
  }
  console.log("Provider detected:", provider);
  await provider.request({
    method: "eth_requestAccounts",
  });
  const accounts = await provider.request({
    method: "eth_accounts",
  });
  if (accounts.length === 0) {
    console.error("No accounts found");
    return null;
  }
  const account = accounts[0];
  console.log("Account found:", account);
  return account;
};
getCurrentConnectedAddress();

// export const transferPayment = async () => {
//   // try {
//   const provider = detectCurrentProvider();
//   if (!provider) {
//     console.error("No provider detected");
//     return;
//   }
//   console.log("Provider detected:", provider);
//   const web3 = new Web3(provider);

//   await provider.request({
//     method: "eth_requestAccounts",
//   });

//   const accounts = await provider.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("Account found:", account);

//   const currentChainId = await getCurrentChainId();
//   if (!currentChainId) {
//     console.error("Unable to retrieve current Chain ID");
//     return;
//   }
//   console.log("Current Chain ID:", currentChainId);

//   const paymentTokenAddress = await getCurrentChainIdStableCoinAddress(
//     currentChainId
//   );
//   if (!paymentTokenAddress) {
//     console.error("Unable to retrieve stablecoin address for chain ID");
//     return;
//   }
//   console.log("Payment Token Address:", paymentTokenAddress);

//   const contracts = new web3.eth.Contract(abiUSDToken, paymentTokenAddress);
//   console.log("contracts", contracts);

//   const tokenPayDecimals = await contracts.methods.decimals().call();
//   console.log("tokenPayDecimals", tokenPayDecimals);

//   const tokenPayAmount = (
//     Number(TokenPayAmount) *
//     10 ** Number(tokenPayDecimals)
//   ).toString();
//   console.log("tokenPayAmount", tokenPayAmount);

//   // Estimate gas limit
//   const estimatedGasLimit = await contracts.methods
//     .transfer(AdminPaymentAddress, tokenPayAmount)
//     .estimateGas({
//       from: account,
//     });
//   console.log("Estimated Gas Limit:", estimatedGasLimit);

//   // Get current gas price
//   const gasPrice = await web3.eth.getGasPrice();
//   console.log("Gas Price:", gasPrice);

//   // Perform the token transfer
//   const resTransferPayment = await contracts.methods
//     .transfer(AdminPaymentAddress, tokenPayAmount)
//     .send({
//       from: account,
//       gas: estimatedGasLimit,
//       gasPrice: gasPrice,
//     });
//   console.log("Transfer Successful:", resTransferPayment);
//   const txExplorer = await getCurrentTxExplorer();
//   const txHashResponse = {
//     txHash: txExplorer + resTransferPayment.transactionHash,
//     status: resTransferPayment.status,
//   };
//   console.log("txHashResponse", txHashResponse);

//   return txHashResponse;
//   // } catch (error) {
//   //   console.error("Error Transfer Payment:", error);
//   // }
// };
