export const detectCurrentProvider = () => {
  const provider =
    window.ethereum || (window.web3 && window.web3.currentProvider);
  console.log("detectCurrentProvider", provider);
  if (!provider) {
    console.error("No web3 instance detected.");
    return null;
  }
  const providerType = provider.isMetaMask
    ? "MetaMask"
    : provider.isCoinbaseWallet
    ? "Coinbase Wallet"
    : provider.isStatus
    ? "Status"
    : provider.isWalletConnect
    ? "WalletConnect"
    : provider.isImToken
    ? "imToken"
    : provider.isTrustWallet
    ? "Trust Wallet"
    : typeof provider === "object" &&
      provider.constructor.name === "Web3Provider"
    ? "Web3 Provider"
    : typeof window.tpProvider !== "undefined"
    ? "Token Pocket"
    : null;
  console.log(`Connected to ${providerType}`);
  return provider;
};
detectCurrentProvider();

export const getRPCProvider = (chainId) => {
  const rpcUrls = {
    1: `https://eth.llamarpc.com/`,
    56: "https://bsc-dataseed.binance.org/",
    137: "https://rpc-mainnet.maticvigil.com/",
    43114: "https://api.avax.network/ext/bc/C/rpc",
    250: "https://rpc.ftm.tools/",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  };
  const rpcUrl = rpcUrls[chainId] || null;
  if (!rpcUrl) {
    console.error(`Unsupported chain ID: ${chainId}`);
  }
  return rpcUrl;
};

export const RPCURL = "https://avalanche-fuji-c-chain-rpc.publicnode.com";
export const USDAddress = "0xA07566Db17C9608EB019527b1db3245e59dA33e2";
export const networkRouterAddress =
  "0x81998918B841D3FE27C244585f6e81446d4A5E71";
export const AdminPaymentAddress = "0x2948eD8D8540D260b19e8b77349cDf47628b66BB";
export const TokenPayAmount = "100";
export const GasEfficiency = 70; //in percentage
