import { Form, Row, Col, Button } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
// import TaxSwitchDropdown from "../FormsComponents/TaxSwitchDropdown";
// import TaxSwitchCheckbox from "../FormsComponents/TaxSwitchCheckbox";
import { useEffect, useState } from "react";
import Input from "../FormsComponents/Input";
import SwitchCheckboxInputTaxes from "../FormsComponents/SwitchCheckboxInputTaxes";
// import TaxDividendDropdown from "../FormsComponents/TaxDividendDropdown";
import TaxInput from "../FormsComponents/TaxInput";
import { useDispatch, useSelector } from "react-redux";
import { setTaxesApi } from "../../Store/Slices/AppSlice";
import axios from "axios";
import { AiOutlinePercentage } from "react-icons/ai";
import "../FormsComponents/Switch.css";
import { scrollToTop } from "../../Utils/helperFunction";
import {
  setDefaultExchange,
  setDefaultExchangeSelect,
  setCustomExchangeInput,
  setSelectedOptionData,
} from "../../Store/Slices/DefaultExchangeSlice";
import RefreshModal from "../RefreshModal";
import RefreshIcon from "../../Images/Logos/Refresh.svg";
import { Routeraddress } from "./router";
import Web3 from "web3";
import InfoIcon from "../../Images/Logos/infoicon.svg";
import GlobalIcon from "../../Images/Logos/globalicon.svg";
import ExploreIcon from "../../Images/Logos/explorericon.png";
import { resetAll } from "../../Utils/helperFunction";
import { setResetPayment } from "../../Store/Slices/ResetPaymentSlice";
import useWalletInfo from "../../Hooks/WalletInfo/useWalletInfo";
// import { validateTaxInput } from "../../Utils/helperFunction";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Taxes({ setTab, setStepper }) {
  const dispatch = useDispatch();
  // const defaultExchangeOptions = [
  //   "Elk Finance (Polygon Mumbai)",
  //   "Custom exchange",
  // ];
  const dividendOptions = ["MATIC", "Custom exchange"];

  const [errors, setErrors] = useState({});
  console.log(errors);

  const { chainId, isConnected , address } = useWalletInfo();

  // console.log("Getting Address in the Taxes" , address);
  

  // // Getting Chain Id from Localstorage and Logic for Options
  // const [chainID, setChainID] = useState(null);
  // const [selectedOptionData, setSelectedOptionData] = useState(null);

  // useEffect(() => {
  //   const initializeWeb3 = async () => {
  //     try {
  //       // Check if MetaMask is installed
  //       if (typeof window.ethereum !== "undefined") {
  //         // Initialize Web3 with MetaMask's provider
  //         const web3 = new Web3(window.ethereum);

  //         // Get the initial chain ID
  //         const initialChainId = await web3.eth.getChainId();
  //         setChainID(initialChainId);
  //         // console.log("Initial Chain ID:", initialChainId);

  //         // Listen for chain ID changes
  //         window.ethereum.on("chainChanged", (newChainId) => {
  //           const decimalChainId = parseInt(newChainId, 16);
  //           setChainID(decimalChainId);
  //           localStorage.setItem("chainID", decimalChainId);
  //           // console.log("Updated Chain ID ", newChainId);
  //         });
  //         // Clean up the event listener when the component unmounts
  //         return () => {
  //           window.ethereum.off("chainChanged");
  //         };
  //       } else {
  //         console.warn("MetaMask is not installed");

  //         // Get the chainID from localStorage if MetaMask is not available
  //         const storedChainId = localStorage.getItem("chainID");

  //         // If there is a stored chain ID, update the state
  //         if (storedChainId) {
  //           setChainID(parseInt(storedChainId, 10)); // Convert stored value to integer
  //           console.log("Using stored Chain ID:", storedChainId);
  //         } else {
  //           console.log("No chain ID found in localStorage");
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Chain ID Error in Default Excahnge", error);
  //     }
  //   };

  //   initializeWeb3();
  // }, [chainID]);

  const defaultExchangeOptions = Routeraddress[chainId];
  // console.log("Router Address in Taxes", Routeraddress[chainId]);

  // Handling Wallet Taxes Section starts here
  // Retrieve the walletTaxes from localStorage or set it to an empty array if not found
  const initialWalletTaxes =
    JSON.parse(localStorage.getItem("walletTaxes")) || [];
  const [walletTaxes, setWalletTaxes] = useState(initialWalletTaxes);
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(true);
  // const maxWalletTaxes = 5;

  const handleAddWalletTax = () => {
    if (walletTaxes.length < 5) {
      const newTax = {
        id: walletTaxes.length + 1,
        walletTaxname: "",
        walletRecipientAddress: "",
        walletBuyRate: false,
        walletSellRate: false,
        walletTransferRate: false,
        walletBuyInput: "",
        walletSellInput: "",
        walletTransferRateInput: "",
        walletTaxRateInput: "",
      };
      setWalletTaxes([...walletTaxes, newTax]);
      setIsAddButtonVisible(false);
    }
  };

  const handleDeleteWalletTax = (id) => {
    const updatedTaxes = walletTaxes
      .filter((tax) => tax.id !== id)
      .map((tax, index) => ({
        ...tax,
        id: index + 1,
      }));
    setWalletTaxes(updatedTaxes);
    setIsAddButtonVisible(updatedTaxes.length <= 4);
  };

  let walletBuyInputId;
  let walletSellInputId;
  let walletTransferRateInputId;
  let walletTaxRateInputId;

  const handleWalletInputChange = (id, field, value) => {
    // Function to validate input value
    const validateInput = (value, field, errorKey) => {
      if (value === "") {
        setWalletTaxes(
          walletTaxes.map((tax) =>
            tax.id === id ? { ...tax, [field]: value } : tax
          )
        );
        setErrors((prevError) => ({
          ...prevError,
          [errorKey]: "0% (disabled) ≤ Total tax ≤ 25%",
        }));
        return false;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setWalletTaxes(
            walletTaxes.map((tax) =>
              tax.id === id ? { ...tax, [field]: value } : tax
            )
          );
          setErrors((prevError) => ({ ...prevError, [errorKey]: "" }));

          return true;
        }
      }

      return false;
    };

    if (field === "walletBuyInput") {
      if (walletBuyInputId) {
        clearTimeout(walletBuyInputId);
      }

      if (validateInput(value, field, "walletBuyInput")) {
        walletBuyInputId = setTimeout(() => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            walletBuyInput: "",
          }));
        }, 500);
      }
    } else if (field === "walletSellInput") {
      if (walletSellInputId) {
        clearTimeout(walletSellInputId);
      }

      if (validateInput(value, field, "walletSellInput")) {
        walletSellInputId = setTimeout(() => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            walletSellInput: "",
          }));
        }, 500);
      }
    } else if (field === "walletTransferRateInput") {
      if (walletTransferRateInputId) {
        clearTimeout(walletTransferRateInputId);
      }

      if (validateInput(value, field, "walletTransferRateInput")) {
        walletTransferRateInputId = setTimeout(() => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            walletTransferRateInput: "",
          }));
        }, 500);
      }
    } else if (field === "walletTaxRateInput") {
      if (walletTaxRateInputId) {
        clearTimeout(walletTaxRateInputId);
      }

      if (validateInput(value, field, "walletTaxRateInput")) {
        walletTaxRateInputId = setTimeout(() => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            walletTaxRateInput: "",
          }));
        }, 500);
      }
    } else {
      setWalletTaxes(
        walletTaxes.map((tax) =>
          tax.id === id ? { ...tax, [field]: value } : tax
        )
      );
    }
  };

  const handleWalletToggleChange = (id, field, value) => {
    setWalletTaxes(
      walletTaxes.map((tax) =>
        tax.id === id ? { ...tax, [field]: value } : tax
      )
    );
  };

  // Save walletTaxes to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("walletTaxes", JSON.stringify(walletTaxes));
  }, [walletTaxes]);
  // WalletTaxesOptions
  const walletTaxesOptions = ["sasd", "ETH", "Other Token"];

  // End of Wallet Taxes Section

  // Getting Token Name from Redux Store
  // const tokenName = useSelector((state) => state.general.tokenName);

  // useEffect(() => {
  //   setTaxesFormData((prevState) => ({
  //     ...prevState,
  //     tokenName: tokenName,
  //   }));
  // }, [tokenName]);

  // Get Default Exchange Values from Redux
  const {
    defaultExchange,
    defaultExchangeSelect,
    customExchangeInput,
    selectedOptionData,
  } = useSelector((state) => state.defaultExchange);

  const localstorageInfo = JSON.parse(localStorage.getItem("formData"));
  // console.log("Local Data", localstorageInfo);
  const initialFormData = {
    defaultExchange: localstorageInfo?.defaultExchange || defaultExchange,
    defaultExchangeSelect: defaultExchangeSelect,
    customExchangeInput: customExchangeInput,
    liquidityTax: false,
    liquidityBuyRate: false,
    liquiditySellRate: false,
    liquidityTransferRate: false,
    liquidityBuyInput: "",
    liquiditySellInput: "",
    liquidityTransferInput: "",
    dividendTax: false,
    dividendSelect: "MATIC",
    dividendCustomTokenInput: "",
    dividendBuyRate: false,
    dividendSellRate: false,
    dividendTransferRate: false,
    dividendBuyInput: "",
    dividendSellInput: "",
    dividendTransferInput: "",
    dividendEligibilityAmount: "",
    autoClaimIntervalInput: "",
    autoClaimIntervalTime: "Seconds",
    gasAutoClaimInput: "",
    swapRatioInput: "",
    autoBurnTax: false,
    autoBurnTaxInput: "",
    autoBurnBuyRate: false,
    autoBurnSellRate: false,
    autoBurnTransferRate: false,
    autoBurnBuyInput: "",
    autoBurnSellInput: "",
    autoBurnTransferInput: "",
    devTax: false,
  };

  // Initialize state from localStorage or default values
  const [taxesFormData, setTaxesFormData] = useState(() => {
    const savedFormData = localStorage.getItem("taxesFormData");
    return savedFormData ? JSON.parse(savedFormData) : initialFormData;
  });

  // Update localStorage whenever taxesFormData changes
  useEffect(() => {
    localStorage.setItem("taxesFormData", JSON.stringify(taxesFormData));
  }, [taxesFormData]);

  const initialSupply = useSelector((store) =>
    store.general.initialSupply.replace(/\s/g, "")
  );
  // console.log("Initial Supply", initialSupply);
  const initialSupplyNumber = parseInt(initialSupply, 10);

  const maxDividendAmount = Math.floor(initialSupplyNumber * 0.01);
  // console.log("1% of Initial Supply : ", maxDividendAmount);

  const validateForm = () => {
    let formErrors = {};

    // Validation for Default Excahnge Select
    if (defaultExchange == true) {
      if (defaultExchangeSelect === "") {
        formErrors.defaultExchangeSelect = "Please select a default exchange.";
      }
    }

    if (defaultExchange == true && defaultExchangeSelect == "Custom exchange") {
      if (!customExchangeInput) {
        formErrors.customExchangeInput = "Enter valid RouterV2 address";
      }
    }
    // Validation for Liquidity tax
    if (defaultExchange == true && taxesFormData.liquidityTax == true) {
      if (taxesFormData.liquidityBuyRate == true) {
        if (!taxesFormData.liquidityBuyInput) {
          formErrors.liquidityBuyInput = "0% (disabled) ≤ Total buy tax ≤ 25%";
        }
      }

      // Validation for Liquidity tax sell input
      if (taxesFormData.liquiditySellRate == true) {
        if (!taxesFormData.liquiditySellInput) {
          formErrors.liquiditySellInput =
            "0% (disabled) ≤ Total Sell tax ≤ 25%";
        }
      }
      // Validation for Liquidity tax Transfer input
      if (taxesFormData.liquidityTransferRate == true) {
        if (!taxesFormData.liquidityTransferInput) {
          formErrors.liquidityTransferInput =
            "0% (disabled) ≤ Total Transfer tax ≤ 25%";
        }
      }
    }

    // Validation for Dividend tax
    if (defaultExchange == true && taxesFormData.dividendTax == true) {
      if (taxesFormData.dividendBuyRate == true) {
        if (!taxesFormData.dividendBuyInput) {
          formErrors.dividendBuyInput = "0% (disabled) ≤ Total buy tax ≤ 25%";
        }
      }
      if (taxesFormData.dividendSellRate == true) {
        if (!taxesFormData.dividendSellInput) {
          formErrors.dividendSellInput = "0% (disabled) ≤ Total Sell tax ≤ 25%";
        }
      }
      if (taxesFormData.dividendTransferRate == true) {
        if (!taxesFormData.dividendTransferInput) {
          formErrors.dividendTransferInput =
            "0% (disabled) ≤ Total Transfer tax ≤ 25%";
        }
      }
    }

    // Validation for Auto Burn tax checkbox
    if (defaultExchange == true && taxesFormData.autoBurnTax == true) {
      if (taxesFormData.autoBurnBuyRate == true) {
        if (!taxesFormData.autoBurnBuyInput) {
          formErrors.autoBurnBuyInput = "0% (disabled) ≤ Total buy tax ≤ 25%";
        }
      }
      if (taxesFormData.autoBurnSellRate == true) {
        if (!taxesFormData.autoBurnSellInput) {
          formErrors.autoBurnSellInput = "0% (disabled) ≤ Total Sell tax ≤ 25%";
        }
      }
      if (taxesFormData.autoBurnTransferRate == true) {
        if (!taxesFormData.autoBurnTransferInput) {
          formErrors.autoBurnTransferInput =
            "0% (disabled) ≤ Total Transfer tax ≤ 25%";
        }
      }
    }

    // Validatio for Dividend Custom TOken Address
    if (
      taxesFormData.dividendTax == true &&
      taxesFormData.dividendSelect == "Custom exchange"
    ) {
      const value = taxesFormData.dividendCustomTokenInput;
      const isValidAddress = /^0x[a-fA-F0-9]{40}$/.test(value);
      const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      if (!isValidAddress) {
        formErrors.dividendCustomTokenInput =
          "Enter address of the desired ERC-20 token.";
      }
    }

    // Validation for Gas Auto Claim
    if (taxesFormData.dividendTax == true) {
      const gasValue = parseInt(
        taxesFormData.gasAutoClaimInput.replace(/\s/g, ""),
        10
      );
      if (!gasValue || gasValue < 200000 || gasValue > 500000) {
        formErrors.gasAutoClaimInput = "200 000 ≤ Gas units ≤ 500 000";
      }
    }
    // Validation for Dividend Eligibility Amount
    if (taxesFormData.dividendTax == true) {
      if (!taxesFormData.dividendEligibilityAmount) {
        formErrors.dividendEligibilityAmount =
          "1.0 ≤ Dividend eligibility amount ≤ 1% of Initial supply";
      }

      const numericValue = parseFloat(
        taxesFormData.dividendEligibilityAmount.replace(/\s/g, "")
      );
      if (
        isNaN(numericValue) ||
        numericValue < 1.0 ||
        numericValue > maxDividendAmount
      ) {
        formErrors.dividendEligibilityAmount =
          "1.0 ≤ Dividend eligibility amount ≤ 1% of Initial supply";
      }
    }

    // Validation for Auto-claim Interval
    if (taxesFormData.dividendTax === true) {
      if (!taxesFormData.autoClaimIntervalInput) {
        formErrors.autoClaimIntervalInput =
          "1 minute ≤ Auto-claim interval ≤ 7 days";
      } else {
        const interval = parseInt(taxesFormData.autoClaimIntervalInput, 10);
        const unit = taxesFormData.autoClaimIntervalTime;

        let isValid = false;

        switch (unit) {
          case "Seconds":
            isValid = interval >= 60 && interval <= 604800; // 1 minute (60 seconds) to 7 days (604800 seconds)
            break;
          case "Minutes":
            isValid = interval >= 1 && interval <= 10080; // 1 minute to 7 days (10080 minutes)
            break;
          case "Hours":
            isValid = interval >= 1 && interval <= 168; // 1 minute to 7 days (168 hours)
            break;
          case "Days":
            isValid = interval >= 1 && interval <= 7; // 1 minute to 7 days (7 days)
            break;
          default:
            isValid = false;
        }

        if (!isValid) {
          formErrors.autoClaimIntervalInput =
            "1 minute ≤ Auto-claim interval ≤ 7 days";
        }
      }
    }
    // Validation for AutoBurnTax
    if (defaultExchange == false && taxesFormData.autoBurnTax == true) {
      if (!taxesFormData.autoBurnTaxInput) {
        formErrors.autoBurnTaxInput = "0% (disabled) ≤ Total tax ≤ 25%";
      }
    }

    // Validation for Swap threshold ratio
    if (
      taxesFormData.liquidityTax == true ||
      taxesFormData.dividendTax == true
    ) {
      if (!taxesFormData.swapRatioInput) {
        formErrors.swapRatioInput =
          "0.01% of liquidity ≤ Swap threshold ratio ≤ 5% of liquidity";
      }
      if (
        taxesFormData.swapRatioInput < 0.01 ||
        taxesFormData.swapRatioInput > 5
      ) {
        formErrors.swapRatioInput =
          "0.01% of liquidity ≤ Swap threshold ratio ≤ 5% of liquidity";
      }
    }

    if (taxesFormData.devTax == true) {
      walletTaxes.forEach((tax, index) => {
        if (!/^[A-Za-z0-9\s]+$/.test(tax.walletTaxname)) {
          formErrors[`walletTaxname${index}`] = "Max 40 characters.";
        }
        if (tax.walletTaxname.length > 40) {
          formErrors[`walletTaxnameLength${index}`] =
            "Tax name should not be greater than 40 characters.";
        }
        if (!/^0x[a-fA-F0-9]{40}$/.test(tax.walletRecipientAddress)) {
          formErrors[`walletRecipientAddress${index}`] =
            "Invalid wallet address. It should start with '0x' and be 40 characters long.";
        }
        if (tax.walletTaxname.length > 40) {
          formErrors[`walletRecipientAddress${index}`] =
            "Invalid wallet address. It should start with '0x' and be 40 characters long.";
        }
        // Validate walletBuyInput
        if (defaultExchange == true && tax.walletBuyRate == true) {
          if (
            !/^\d{1,2}(\.\d{0,2})?$/.test(tax.walletBuyInput) ||
            isNaN(parseFloat(tax.walletBuyInput)) ||
            parseFloat(tax.walletBuyInput) < 0 ||
            parseFloat(tax.walletBuyInput) > 25
          ) {
            formErrors[`walletBuyInput${index}`] =
              "0% (disabled) ≤ Total tax ≤ 25%";
          }
        }

        // Validate walletSellInput

        if (defaultExchange == true && tax.walletSellRate == true) {
          if (
            !/^\d{1,2}(\.\d{0,2})?$/.test(tax.walletSellInput) ||
            isNaN(parseFloat(tax.walletSellInput)) ||
            parseFloat(tax.walletSellInput) < 0 ||
            parseFloat(tax.walletSellInput) > 25
          ) {
            formErrors[`walletSellInput${index}`] =
              "0% (disabled) ≤ Total tax ≤ 25%";
          }
        }

        // Validate walletTransferRateInput
        if (defaultExchange == true && tax.walletTransferRate == true) {
          if (
            !/^\d{1,2}(\.\d{0,2})?$/.test(tax.walletTransferRateInput) ||
            isNaN(parseFloat(tax.walletTransferRateInput)) ||
            parseFloat(tax.walletTransferRateInput) < 0 ||
            parseFloat(tax.walletTransferRateInput) > 25
          ) {
            formErrors[`walletTransferRateInput${index}`] =
              "0% (disabled) ≤ Total tax ≤ 25%";
          }
        }
        // Tax Rate Input
        if (taxesFormData.devTax == true && defaultExchange == false) {
          if (
            !/^\d{1,2}(\.\d{0,2})?$/.test(tax.walletTaxRateInput) ||
            isNaN(parseFloat(tax.walletTaxRateInput)) ||
            parseFloat(tax.walletTaxRateInput) < 0 ||
            parseFloat(tax.walletTaxRateInput) > 25
          ) {
            formErrors[`walletTaxRateInput${index}`] =
              "0% (disabled) ≤ Total tax ≤ 25%";
          }
        }
      });
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  let dividendCustomTokenInputId,
    dividendEligibilityAmountId,
    swapRatioInputId,
    autoClaimIntervalInputId,
    gasAutoClaimInputId,
    liquidityBuyInputId,
    liquiditySellInputId,
    liquidityTransferInputId,
    dividendBuyInputId,
    dividendSellInputId,
    dividendTransferInputId,
    autoBurnBuyInputId,
    autoBurnSellInputId,
    autoBurnTransferInputId,
    autoBurnTaxInputId;

  // Event handler for form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (dividendCustomTokenInputId) {
      clearTimeout(dividendCustomTokenInputId);
    }
    if (dividendEligibilityAmountId) {
      clearTimeout(dividendEligibilityAmountId);
    }
    if (swapRatioInputId) {
      clearTimeout(swapRatioInputId);
    }
    if (autoClaimIntervalInputId) {
      clearTimeout(autoClaimIntervalInputId);
    }
    if (gasAutoClaimInputId) {
      clearTimeout(gasAutoClaimInputId);
    }
    if (liquidityBuyInputId) {
      clearTimeout(liquidityBuyInputId);
    }
    if (liquiditySellInputId) {
      clearTimeout(liquiditySellInputId);
    }
    if (liquidityTransferInputId) {
      clearTimeout(liquidityTransferInputId);
    }
    if (dividendBuyInputId) {
      clearTimeout(dividendBuyInputId);
    }
    if (dividendSellInputId) {
      clearTimeout(dividendSellInputId);
    }
    if (dividendTransferInputId) {
      clearTimeout(dividendTransferInputId);
    }
    if (autoBurnBuyInputId) {
      clearTimeout(autoBurnBuyInputId);
    }
    if (autoBurnSellInputId) {
      clearTimeout(autoBurnSellInputId);
    }
    if (autoBurnTransferInputId) {
      clearTimeout(autoBurnTransferInputId);
    }
    if (autoBurnTaxInputId) {
      clearTimeout(autoBurnTaxInputId);
    }

    if (name === "defaultExchangeSelect") {
      dispatch(setDefaultExchangeSelect({ name: name, value: value }));
      const selectedOption = defaultExchangeOptions.find(
        (option) => option.name === value
      );
      setTaxesFormData({ ...taxesFormData, [name]: [value] });
      // setSelectedOptionData(selectedOption);
      dispatch(setSelectedOptionData(selectedOption));
    }
    if (name === "customExchangeInput") {
      dispatch(setCustomExchangeInput({ name: name, value: value }));
    }
    // Validation for Liquidity tax Buy Input
    if (name === "liquidityBuyInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          liquidityBuyInput: "0% (disabled) ≤ Total buy tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({ ...prevError, liquidityBuyInput: "" }));
        }
        liquidityBuyInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              liquidityBuyInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Liquidity tax Sell Input
    else if (name === "liquiditySellInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          liquiditySellInput: "0% (disabled) ≤ Total Sell tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({ ...prevError, liquiditySellInput: "" }));
        }
        liquiditySellInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              liquiditySellInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Liquidity tax Transfer Input
    else if (name === "liquidityTransferInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          liquidityTransferInput: "0% (disabled) ≤ Total Transfer tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({
            ...prevError,
            liquidityTransferInput: "",
          }));
        }
        liquidityTransferInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              liquidityTransferInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Dividend tax Buy Input
    else if (name === "dividendBuyInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          dividendBuyInput: "0% (disabled) ≤ Total Buy tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({ ...prevError, dividendBuyInput: "" }));
        }
        dividendBuyInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              dividendBuyInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Dividend tax Sell Input
    else if (name === "dividendSellInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          dividendSellInput: "0% (disabled) ≤ Total Sell tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({ ...prevError, dividendSellInput: "" }));
        }
        dividendSellInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              dividendSellInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Dividend tax Transfer Input
    else if (name === "dividendTransferInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          dividendTransferInput: "0% (disabled) ≤ Total Transfer tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({
            ...prevError,
            dividendTransferInput: "",
          }));
        }
        dividendTransferInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              dividendTransferInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Auto-burn tax Buy Input
    else if (name === "autoBurnBuyInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          autoBurnBuyInput: "0% (disabled) ≤ Total Buy tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({ ...prevError, autoBurnBuyInput: "" }));
        }
        autoBurnBuyInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              autoBurnBuyInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Auto-burn tax Sell Input
    else if (name === "autoBurnSellInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          autoBurnSellInput: "0% (disabled) ≤ Total Sell tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({ ...prevError, autoBurnSellInput: "" }));
        }
        autoBurnSellInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              autoBurnSellInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Auto-burn tax Transfer Input
    else if (name === "autoBurnTransferInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          autoBurnTransferInput: "0% (disabled) ≤ Total Transfer tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({
            ...prevError,
            autoBurnTransferInput: "",
          }));
        }
        autoBurnTransferInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              autoBurnTransferInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for default Custom Exchange Input
    else if (name === "customExchangeInput") {
      if (value.length > 42) {
        setTaxesFormData({ ...taxesFormData, [name]: value.slice(0, 42) });
      } else if (/[^a-zA-Z0-9]/.test(value)) {
        setTaxesFormData({
          ...taxesFormData,
          [name]: value.replace(/[^a-zA-Z0-9]/g, ""),
        });
      } else {
        setTaxesFormData({ ...taxesFormData, [name]: value });
      }
    }
    // Validation for Divident Custom Token Input
    else if (name === "dividendCustomTokenInput") {
      setTaxesFormData({ ...taxesFormData, [name]: value.slice(0, 42) });
      dividendCustomTokenInputId = setTimeout(() => {
        const isValidAddress = /^0x[a-fA-F0-9]{40}$/.test(value);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(value);
        if (isValidAddress && !hasSpecialChars) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dividendCustomTokenInput: "",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dividendCustomTokenInput:
              "Enter a valid ERC-20 token address without special characters.",
          }));
        }
      }, 500);
    }
    // Validation for Swap Ratio Input
    else if (name === "swapRatioInput") {
      const regex = /^[0-9](\.\d{0,2})?$/;
      if (regex.test(value) || value === "") {
        if (value < 0.01 || value > 5) {
          setErrors((prevError) => ({
            ...prevError,
            swapRatioInput:
              "0.01% of liquidity ≤ Swap threshold ratio ≤ 5% of liquidity",
          }));
        }
        setTaxesFormData({ ...taxesFormData, [name]: value });
        swapRatioInputId = setTimeout(() => {
          if (value >= 0.01 && value <= 5) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              swapRatioInput: "",
            }));
          }
        }, 500);
      }
    }
    // Validation for Gas Auto Claim Input
    else if (name === "gasAutoClaimInput") {
      // Remove any non-digit characters
      const numericValue = value.replace(/\D/g, "");

      if (numericValue.length > 6) {
        return;
      }

      // Format the number with spaces
      const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      // Convert the formatted value back to a number
      const intValue = parseInt(numericValue, 10);

      // Validate the range
      if (intValue >= 200000 && intValue <= 500000) {
        setTaxesFormData({ ...taxesFormData, [name]: formattedValue });
      } else {
        setTaxesFormData({ ...taxesFormData, [name]: formattedValue });
      }
      gasAutoClaimInputId = setTimeout(() => {
        if (intValue >= 200000 && intValue <= 500000) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            gasAutoClaimInput: "",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            gasAutoClaimInput: "200 000 ≤ Gas units ≤ 500 000",
          }));
        }
      }, 500);
    }
    // Validation for Dividend Eligibility amount
    else if (name === "dividendEligibilityAmount") {
      let formattedValue = value.replace(/[^\d.]/g, "");

      if ((formattedValue.match(/\./g) || []).length > 1) {
        return;
      }

      // formattedValue = value.replace(/\D/g, "");

      if (formattedValue.length > 16) {
        return;
      }
      formattedValue = formattedValue.replace(/(\.\d?).*$/, "$1");

      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      const numericValue = parseInt(formattedValue.replace(/\s/g, ""), 10);

      setTaxesFormData({ ...taxesFormData, [name]: formattedValue });

      dividendEligibilityAmountId = setTimeout(() => {
        if (numericValue >= 1.0 && numericValue <= 10000) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dividendEligibilityAmount: "",
          }));
        }
      }, 500);
    }
    // Validation for auto claim interval input
    else if (name === "autoClaimIntervalInput") {
      const formattedValue = value.replace(/\D/g, "").slice(0, 5);
      setTaxesFormData({ ...taxesFormData, [name]: formattedValue });
      autoClaimIntervalInputId = setTimeout(() => {
        const interval = parseInt(formattedValue, 10);
        const unit = taxesFormData.autoClaimIntervalTime;

        let isValid = false;

        switch (unit) {
          case "Seconds":
            isValid = interval >= 60 && interval <= 604800; // 1 minute (60 seconds) to 7 days (604800 seconds)
            break;
          case "Minutes":
            isValid = interval >= 1 && interval <= 10080; // 1 minute to 7 days (10080 minutes)
            break;
          case "Hours":
            isValid = interval >= 1 && interval <= 168; // 1 minute to 7 days (168 hours)
            break;
          case "Days":
            isValid = interval >= 1 && interval <= 7; // 1 minute to 7 days (7 days)
            break;
          default:
            isValid = false;
        }

        if (isValid) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            autoClaimIntervalInput: "",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            autoClaimIntervalInput: "1 minute ≤ Auto-claim interval ≤ 7 days",
          }));
        }
      }, 500);
    }
    // Validation for auto burn tax
    else if (name === "autoBurnTaxInput") {
      if (value === "") {
        setTaxesFormData({ ...taxesFormData, [name]: value });
        setErrors((prevError) => ({
          ...prevError,
          autoBurnTaxInput: "0% (disabled) ≤ Total tax ≤ 25%",
        }));
        return;
      }

      if (/^\d{1,2}(\.\d{0,2})?$/.test(value)) {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 25) {
          setTaxesFormData({ ...taxesFormData, [name]: value });
          setErrors((prevError) => ({
            ...prevError,
            autoBurnTaxInput: "",
          }));
        }
        autoBurnTaxInputId = setTimeout(() => {
          if (floatValue >= 0 && floatValue <= 25) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              autoBurnTaxInput: "",
            }));
          }
        }, 500);
      }
    } else {
      setTaxesFormData({ ...taxesFormData, [name]: value });
    }
    dispatch(setResetPayment(true));
  };

  useEffect(() => {
    if (taxesFormData.devTax && walletTaxes.length === 0) {
      handleAddWalletTax();
    }
  }, [taxesFormData.devTax]);

  console.log("Taxes Form Data", taxesFormData);
  // Event handler for toggle switches
  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    if (name === "defaultExchange") {
      const newToggleValue = !defaultExchange;
      dispatch(setDefaultExchange({ name: name, checked: newToggleValue }));
    }
    setTaxesFormData({ ...taxesFormData, [name]: checked });
    dispatch(setResetPayment(true));
  };

  const tokenName = localStorage.getItem("tokenName") || "";
  // const walletAddress = localStorage.getItem("walletAddress") || "";
  const ID = localStorage.getItem("ID") || "";

  // console.log("tokenName,walletAddress,id", tokenName, walletAddress, ID);
  // Handling Next Step

  const stepUpdate = async () => {
    if (isConnected == false) {
      // alert("Please ReConnect Your Wallet");
      toast(
        <div>
          <p style={{ color: "white", fontWeight: 500 }}>
            Please Connect Your Wallet First
          </p>
        </div>,
        {
          autoClose: 10000,
          style: {
            background:
              "linear-gradient(224deg, rgba(54, 153, 178, 1) 0%, rgba(16, 207, 163, 1) 94.72%)",
          },
          progressClassName: "teal-toast-progress-bar",
        }
      );
      return;
    }

    if (!validateForm()) return;

    const updatedWalletTaxes = walletTaxes.map((tax) => ({
      ...tax,
      walletBuyInput:
        defaultExchange == true && tax.walletBuyRate == true
          ? Math.round(tax.walletBuyInput * 100)
          : 0,
      walletSellInput:
        defaultExchange == true && tax.walletSellRate == true
          ? Math.round(tax.walletSellInput * 100)
          : 0,
      walletTransferRateInput:
        defaultExchange == true && tax.walletTransferRate == true
          ? Math.round(tax.walletTransferRateInput * 100)
          : 0,
      walletTaxRateInput:
        defaultExchange == false ? Math.round(tax.walletTaxRateInput * 100) : 0,
    }));
    try {
      const response = await axios.put(
        "https://trendifytokens.io/api/files/updatetaxesdata",
        {
          tokenname: tokenName,
          walletaddress: address,
          id: ID,
          taxesdefaultexchange: defaultExchange,
          taxesdefaultexchangeselect: defaultExchange
            ? defaultExchangeSelect
            : "",
          routeraddress:
            defaultExchange &&
            defaultExchangeSelect !== "" &&
            defaultExchangeSelect !== "Custom exchange"
              ? selectedOptionData?.Routeraddress
              : "",
          taxescustomechangeinput:
            defaultExchange && defaultExchangeSelect == "Custom exchange"
              ? customExchangeInput
              : "",
          taxesliquiditytax: defaultExchange
            ? taxesFormData.liquidityTax
            : false,
          liquiditybuytaxrate:
            defaultExchange && taxesFormData.liquidityTax
              ? taxesFormData.liquidityBuyRate
              : false,
          liquiditybuytaxrateinput:
            defaultExchange &&
            taxesFormData.liquidityTax &&
            taxesFormData.liquidityBuyRate
              ? Math.round(taxesFormData.liquidityBuyInput * 100)
              : "0",
          liquidityselltaxrate:
            defaultExchange && taxesFormData.liquidityTax
              ? taxesFormData.liquiditySellRate
              : false,
          liquidityselltaxrateinput:
            defaultExchange &&
            taxesFormData.liquidityTax &&
            taxesFormData.liquiditySellRate
              ? Math.round(taxesFormData.liquiditySellInput * 100)
              : "0",
          liquiditytransfertaxrate:
            defaultExchange && taxesFormData.liquidityTax
              ? taxesFormData.liquidityTransferRate
              : false,
          liquiditytransfertaxrateinput:
            defaultExchange &&
            taxesFormData.liquidityTax &&
            taxesFormData.liquidityTransferRate
              ? Math.round(taxesFormData.liquidityTransferInput * 100)
              : "0",
          dividendtax: defaultExchange ? taxesFormData.dividendTax : false,
          dividendtaxselect: taxesFormData.dividendSelect,
          dividendcustomtokenaddressinput:
            taxesFormData.dividendCustomTokenInput,
          dividendbuytaxrate:
            defaultExchange && taxesFormData.dividendTax
              ? taxesFormData.dividendBuyRate
              : false,
          dividendbuytaxrateinput:
            defaultExchange &&
            taxesFormData.dividendTax &&
            taxesFormData.dividendBuyRate
              ? Math.round(taxesFormData.dividendBuyInput * 100)
              : "0",
          dividendselltaxrate:
            defaultExchange && taxesFormData.dividendTax
              ? taxesFormData.dividendSellRate
              : false,
          dividendselltaxrateinput:
            defaultExchange &&
            taxesFormData.dividendTax &&
            taxesFormData.dividendSellRate
              ? Math.round(taxesFormData.dividendSellInput * 100)
              : "0",
          dividendtransfertaxrate:
            defaultExchange && taxesFormData.dividendTax
              ? taxesFormData.dividendTransferRate
              : false,
          dividendtransfertaxrateinput:
            defaultExchange &&
            taxesFormData.dividendTax &&
            taxesFormData.dividendTransferRate
              ? Math.round(taxesFormData.dividendTransferInput * 100)
              : "0",
          dividenteligibilityamount:
            defaultExchange && taxesFormData.dividendTax
              ? taxesFormData.dividendEligibilityAmount
              : "",
          autoclaiminterval:
            defaultExchange && taxesFormData.dividendTax
              ? taxesFormData.autoClaimIntervalInput
              : "",
          autoclaimintervaltime:
            defaultExchange && taxesFormData.dividendTax
              ? taxesFormData.autoClaimIntervalTime
              : "",
          gasautoclaim:
            defaultExchange && taxesFormData.dividendTax
              ? taxesFormData.gasAutoClaimInput?.replace(/\s/g, "")
              : "",
          swapthresholdratio: defaultExchange
            ? taxesFormData.swapRatioInput * 100
            : "",
          autoburtx: taxesFormData.autoBurnTax,
          autoBurnTaxInput:
            defaultExchange == false && taxesFormData.autoBurnTax
              ? Math.round(taxesFormData.autoBurnTaxInput * 100)
              : "",
          autoburnbuytaxrate:
            defaultExchange && taxesFormData.autoBurnTax
              ? taxesFormData.autoBurnBuyRate
              : false,
          autoburnbuytaxrateinput:
            defaultExchange &&
            taxesFormData.autoBurnTax &&
            taxesFormData.autoBurnBuyRate
              ? Math.round(taxesFormData.autoBurnBuyInput * 100)
              : "0",
          autoburnselltaxrate:
            defaultExchange && taxesFormData.autoBurnTax
              ? taxesFormData.autoBurnSellRate
              : false,
          autoburnselltaxrateinput:
            defaultExchange &&
            taxesFormData.autoBurnTax &&
            taxesFormData.autoBurnSellRate
              ? Math.round(taxesFormData.autoBurnSellInput * 100)
              : "0",
          autoburntransfertaxrate:
            defaultExchange && taxesFormData.autoBurnTax
              ? taxesFormData.autoBurnTransferRate
              : false,
          autoburntransfertaxrateinput:
            defaultExchange &&
            taxesFormData.autoBurnTax &&
            taxesFormData.autoBurnTransferRate
              ? Math.round(taxesFormData.autoBurnTransferInput * 100)
              : "0",
          wallettaxes: taxesFormData.devTax == true ? updatedWalletTaxes : [],
        }
      );
      if (response?.data?.status === true) {
        console.log("Taxes Form Response", response);
        dispatch(setTaxesApi());
        setTab("tab4");
        setStepper(3);
        scrollToTop();
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(walletTaxes);
  console.log("Taxes Form Data", taxesFormData);

  const handlePrevious = () => {
    setTab("tab2");
    setStepper(1);
    scrollToTop();
  };

  // Reset Feature
  const [showOuterModal, setShowOuterModal] = useState(false);

  const handleOuterModalOpen = () => setShowOuterModal(true);

  const handleOuterModalClose = () => setShowOuterModal(false);

  // Function for handling refresh
  const handleReset = () => {
    // setTaxesFormData(initialFormData);
    // localStorage.setItem("taxesFormData", JSON.stringify(initialFormData));
    resetAll();
    handleOuterModalClose();
  };

  return (
    <>
      <div
        className="rounded mx-auto"
        style={{
          backgroundColor: "#17171B",
          padding: "2rem",
          width: "95%",
        }}
      >
        <Form>
          <div className="d-flex justify-content-between align-items-center">
            <p
              className="zeroMargin"
              style={{ color: "#9B9797", fontWeight: 500 }}
            >
              Default Exchange
            </p>
            <div>
              <Button
                className="bg-transparent border-0"
                onClick={handleOuterModalOpen}
              >
                <img
                  src={RefreshIcon}
                  alt="refresh"
                  style={{ width: "1rem" }}
                />
              </Button>
              <RefreshModal
                showOuterModal={showOuterModal}
                handleOuterModalClose={handleOuterModalClose}
                handleReset={handleReset}
              />
            </div>
          </div>
          <hr style={{ color: "#9B9797" }} />
          <Row>
            {/* Default Exhange Section Starts From Here */}
            <Col className="my-3" sm={12}>
              <div>
                <Col md={5}>
                  <div className="toggle-switch-container">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name={"defaultExchange"}
                        id={"Default Exchange"}
                        checked={defaultExchange}
                        onChange={(e) => handleToggleChange(e)}
                      />
                      <span className="slider" />
                    </label>
                    <label
                      htmlFor={"Default Exchange"}
                      style={{ color: "#F1F1F1" }}
                    >
                      {"Default Exchange"}
                    </label>
                  </div>

                  <div style={{ color: "#737373" }}>
                    Choose a default exchange (V2 only) where you want to add
                    liquidity. This will allow for more precise configuration
                    and more available features.
                  </div>
                </Col>
                <Col md={12}>
                  {defaultExchange && (
                    <div>
                      <Row className="mt-3">
                        <div className="d-flex flex-column flex-md-row align-items-center gap-3">
                          <Col sm={12} md={4} className="my-2">
                            <Form.Select
                              style={{
                                color: "#737373",
                                margin: "0rem 0rem",
                                borderColor: "#3A3A3A",
                              }}
                              className="bg-transparent placeholderColor"
                              as="select"
                              id="InputText"
                              name="defaultExchangeSelect"
                              value={defaultExchangeSelect}
                              onChange={(e) => handleInputChange(e)}
                            >
                              <option value="" className="bg-dark">
                                Select
                              </option>

                              {defaultExchangeOptions?.map((option, index) => (
                                <option
                                  key={index}
                                  value={option?.name}
                                  className="bg-dark"
                                >
                                  {option?.name}
                                </option>
                              ))}
                            </Form.Select>
                            <div
                              className={`mt-2 ${
                                defaultExchangeSelect == ""
                                  ? "d-block"
                                  : "d-none"
                              }`}
                              style={{ color: "red" }}
                            >
                              {errors.defaultExchangeSelect}
                            </div>
                          </Col>
                          <Col sm={12} md={8}>
                            {selectedOptionData &&
                              selectedOptionData.name !== "Custom exchange" && (
                                <div
                                  style={{
                                    color: "#F1F1F1",
                                    background: "#353535",
                                  }}
                                  className="my-4 d-flex flex-column flex-md-row align-items-center gap-3 px-3 py-2 rounded-2"
                                >
                                  {/* Left Side */}
                                  <div className="d-flex flex-column flex-md-row align-items-center gap-2">
                                    <div>
                                      <img src={InfoIcon} alt="infoIcon" />
                                    </div>
                                    <div>
                                      <p className="fw-bold">
                                        {selectedOptionData.name}
                                      </p>
                                      <p className="mt-2">
                                        To ensure that you have selected the
                                        correct DEX for your token, we recommend
                                        visiting its page.
                                      </p>
                                    </div>
                                  </div>
                                  {/* Right Side */}
                                  <div className="d-flex flex-column flex-md-row align-items-center gap-3">
                                    <div>
                                      <a
                                        href={selectedOptionData.Dexlink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img src={GlobalIcon} alt="Global" />
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href={selectedOptionData.explorerlink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img src={ExploreIcon} alt="Explore" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {selectedOptionData &&
                              selectedOptionData.name === "Custom exchange" && (
                                <Input
                                  Label={"Custom exchange"}
                                  Placeholder={"RouterV2 address"}
                                  Description={"Enter valid RouterV2 address"}
                                  Name={"customExchangeInput"}
                                  onChange={(e) => handleInputChange(e)}
                                  value={customExchangeInput}
                                  width={12}
                                  customExchangeError={
                                    errors.customExchangeInput
                                  }
                                />
                              )}
                          </Col>
                        </div>

                        {/* Liquidity Tax Toggle Switch starts here */}
                        <div className="my-4">
                          <div>
                            <Col md={5}>
                              {/* <Form.Check
                                style={{ color: "#F1F1F1", fontWeight: "500" }}
                                type="switch"
                                id="Switch"
                                name="liquidityTax"
                                checked={taxesFormData.liquidityTax}
                                onChange={(e) => handleToggleChange(e)}
                                label={"Liquidity tax"}
                              /> */}
                              <div className="toggle-switch-container">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name={"liquidityTax"}
                                    id={"Liquidity tax"}
                                    checked={taxesFormData.liquidityTax}
                                    onChange={(e) => handleToggleChange(e)}
                                  />
                                  <span className="slider" />
                                </label>
                                <label
                                  htmlFor={"Liquidity tax"}
                                  style={{ color: "#F1F1F1" }}
                                >
                                  {"Liquidity tax"}
                                </label>
                              </div>
                              <div style={{ color: "#737373" }}>
                                The Liquidity tax will automatically adds
                                collected tokens to your main liquidity pool on
                                the default exchange.
                              </div>
                            </Col>
                            {taxesFormData.liquidityTax && (
                              <SwitchCheckboxInputTaxes
                                Placeholder={"0"}
                                Error={Error}
                                label1={"Buy tax rate"}
                                label2={"Sell tax rate"}
                                label3={"Transfer tax rate"}
                                labelDescription1={
                                  "Specify the exact rate of tax to be charged on buys."
                                }
                                labelDescription2={
                                  "Specify the exact rate of tax to be charged on sells."
                                }
                                labelDescription3={
                                  "Specify the exact rate of tax to be charged on transfers (other types of transactions)."
                                }
                                labelName1={"liquidityBuyRate"}
                                labelName2={"liquiditySellRate"}
                                labelName3={"liquidityTransferRate"}
                                toggleValue1={taxesFormData.liquidityBuyRate}
                                toggleValue2={taxesFormData.liquiditySellRate}
                                toggleValue3={
                                  taxesFormData.liquidityTransferRate
                                }
                                toggleOnchange={(e) => handleToggleChange(e)}
                                InputName1={"liquidityBuyInput"}
                                InputName2={"liquiditySellInput"}
                                InputName3={"liquidityTransferInput"}
                                InputValue1={taxesFormData.liquidityBuyInput}
                                InputValue2={taxesFormData.liquiditySellInput}
                                InputValue3={
                                  taxesFormData.liquidityTransferInput
                                }
                                InputOnChange={(e) => {
                                  handleInputChange(e);
                                }}
                                InputDescription1={
                                  "0% (disabled) ≤ Total buy tax ≤ 25%"
                                }
                                InputDescription2={
                                  "0% (disabled) ≤ Total sell tax ≤ 25%"
                                }
                                InputDescription3={
                                  "0% (disabled) ≤ Total transfer tax ≤ 25%"
                                }
                                liquidityBuyError={errors.liquidityBuyInput}
                                liquiditySellError={errors.liquiditySellInput}
                                liquidityTransferError={
                                  errors.liquidityTransferInput
                                }
                              />
                            )}
                          </div>

                          {/* Liquidity Tax Toggle Switch ends here */}

                          {/* <TaxSwitchCheckbox
                            className="titles"
                            Label="Liquidity tax"
                            Description="After buying or selling address will be temporarily frozen, during which time it can't do any transfers from it and to it. However token scanners may show your token as honeypot if they can't detect this feature correctly."
                          /> */}
                        </div>
                        {/* Liquidity Tax Toggle Switch ends here */}

                        {/* Dividend Tax Toggle DropDown starts here */}
                        {/* <div className="my-2">
                          <div>
                            <Col md={5}>
                              <div className="toggle-switch-container">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name={"dividendTax"}
                                    id={"Dividend tax"}
                                    checked={taxesFormData.dividendTax}
                                    onChange={(e) => handleToggleChange(e)}
                                  />
                                  <span className="slider" />
                                </label>
                                <label
                                  htmlFor={"Dividend tax"}
                                  style={{ color: "#F1F1F1" }}
                                >
                                  {"Dividend tax"}
                                </label>
                              </div>
                              <div style={{ color: "#737373" }}>
                                The Dividend tax will automatically collect
                                tokens, swap them and distribute among the
                                holders.
                              </div>
                            </Col>
                            <Col md={12}>
                              {taxesFormData.dividendTax && (
                                <div>
                                  <Row className="my-4">
                                    <SwitchCheckboxInputTaxes
                                      Placeholder={"0"}
                                      Error={Error}
                                      label1={"Buy tax rate"}
                                      label2={"Sell tax rate"}
                                      label3={"Transfer tax rate"}
                                      labelDescription1={
                                        "Specify the exact rate of tax to be charged on buys."
                                      }
                                      labelDescription2={
                                        "Specify the exact rate of tax to be charged on sells."
                                      }
                                      labelDescription3={
                                        "Specify the exact rate of tax to be charged on transfers (other types of transactions)."
                                      }
                                      labelName1={"dividendBuyRate"}
                                      labelName2={"dividendSellRate"}
                                      labelName3={"dividendTransferRate"}
                                      toggleValue1={
                                        taxesFormData.dividendBuyRate
                                      }
                                      toggleValue2={
                                        taxesFormData.dividendSellRate
                                      }
                                      toggleValue3={
                                        taxesFormData.dividendTransferRate
                                      }
                                      toggleOnchange={(e) =>
                                        handleToggleChange(e)
                                      }
                                      InputName1={"dividendBuyInput"}
                                      InputName2={"dividendSellInput"}
                                      InputName3={"dividendTransferInput"}
                                      InputValue1={
                                        taxesFormData.dividendBuyInput
                                      }
                                      InputValue2={
                                        taxesFormData.dividendSellInput
                                      }
                                      InputValue3={
                                        taxesFormData.dividendTransferInput
                                      }
                                      InputOnChange={(e) => {
                                        handleInputChange(e);
                                      }}
                                      InputDescription1={
                                        "0% (disabled) ≤ Total buy tax ≤ 25%"
                                      }
                                      InputDescription2={
                                        "0% (disabled) ≤ Total sell tax ≤ 25%"
                                      }
                                      InputDescription3={
                                        "0% (disabled) ≤ Total transfer tax ≤ 25%"
                                      }
                                      dividendBuyError={errors.dividendBuyInput}
                                      dividendSellError={
                                        errors.dividendSellInput
                                      }
                                      dividendTransferError={
                                        errors.dividendTransferInput
                                      }
                                    />
                                  </Row>
                                  <Row>
                                    <div className="my-3">
                                      <Row className="mt-2">
                                        <TaxInput
                                          Label={"Dividend eligibility amount"}
                                          Description={
                                            "Specify the minimum amount of tokens each user must hold to be eligible for dividends."
                                          }
                                          InputPlaceholder={"0 Tokens"}
                                          InputDescription={
                                            "1.0 ≤ Dividend eligibility amount ≤ 1% of Initial supply (Not editable later)"
                                          }
                                          Name={"dividendEligibilityAmount"}
                                          value={
                                            taxesFormData.dividendEligibilityAmount
                                          }
                                          onChange={(e) => handleInputChange(e)}
                                          dividendEligibilityAmountError={
                                            errors.dividendEligibilityAmount
                                          }
                                        />
                                      </Row>
                                      <Row className="my-3">
                                        <TaxInput
                                          Label={"Auto-claim interval"}
                                          Description={
                                            "Set the minimum interval for auto-claim of dividends. Does not affect manual claiming."
                                          }
                                          InputPlaceholder={"0"}
                                          InputDescription={
                                            "1 minute ≤ Auto-claim interval ≤ 7 days"
                                          }
                                          Options={[
                                            "Seconds",
                                            "Minutes",
                                            "Hours",
                                            "Days",
                                          ]}
                                          Placeholder="Select"
                                          Name={"autoClaimIntervalInput"}
                                          value={
                                            taxesFormData.autoClaimIntervalInput
                                          }
                                          onChange={(e) => handleInputChange(e)}
                                          selectName="autoClaimIntervalTime"
                                          selectValue={
                                            taxesFormData.autoClaimIntervalTime
                                          }
                                          selectOnChange={(e) =>
                                            handleInputChange(e)
                                          }
                                          autoClaimIntervalError={
                                            errors.autoClaimIntervalInput
                                          }
                                        />
                                      </Row>
                                      <Row>
                                        <TaxInput
                                          Label={"Gas for auto-claims"}
                                          Description={
                                            "Set the amount of gas units to take from each transaction for auto-claim of dividends. If you are not sure, we recommend leaving it at 300000."
                                          }
                                          InputPlaceholder={"300000"}
                                          InputDescription={
                                            "200 000 ≤ Gas units ≤ 500 000"
                                          }
                                          Name={"gasAutoClaimInput"}
                                          value={
                                            taxesFormData.gasAutoClaimInput
                                          }
                                          onChange={(e) => handleInputChange(e)}
                                          gasAutoClaimError={
                                            errors.gasAutoClaimInput
                                          }
                                        />
                                      </Row>
                                    </div>
                                  </Row>
                                </div>
                              )}
                            </Col>
                          </div>
                        </div> */}
                        {/* Dividend Tax Toggle DropDown ends here */}
                      </Row>
                      {/* Swap Threshold Ratio */}
                      <Row className="mt-3">
                        <TaxInput
                          Label={"Swap threshold ratio"}
                          Description={
                            "When the amount of tokens collected from taxes reaches the swap threshold, they will be swapped and sent to the correct locations. Set the relative liquidity rate for the swap threshold to better control the selling pressure. If you are not sure, we recommend leaving it at 0.5%."
                          }
                          InputPlaceholder={"0.5"}
                          InputDescription={
                            "0.01% of liquidity ≤ Swap threshold ratio ≤ 5% of liquidity."
                          }
                          Name={"swapRatioInput"}
                          value={taxesFormData.swapRatioInput}
                          onChange={(e) => handleInputChange(e)}
                          swapRatioInputError={errors.swapRatioInput}
                          percentage={true}
                        />
                      </Row>
                    </div>
                  )}
                </Col>
              </div>
            </Col>
          </Row>

          {/* Wallet Taxes Section Starts From Here */}

          <Col md={5}>
            <div className="toggle-switch-container">
              <label className="switch">
                <input
                  type="checkbox"
                  name={"devTax"}
                  id={"Dev Tax"}
                  checked={taxesFormData.devTax}
                  onChange={(e) => handleToggleChange(e)}
                />
                <span className="slider" />
              </label>
              <label htmlFor={"Dev Tax"} style={{ color: "#F1F1F1" }}>
                {"Wallet Tax"}
              </label>
            </div>
            <div style={{ color: "#737373" }}>
              Wallet taxes will automatically send the specified currency to the
              wallets you select.
            </div>
          </Col>
          {/* <p className="titles">Dev Taxes</p> */}
          {/* <hr style={{ color: "#9B9797" }} /> */}
          {/* <Stack direction="horizontal" gap={3}>
            <div>
              <p className="mt-3" style={{ color: "#737373" }}>
                Wallet taxes will automatically send the specified currency to
                the wallets you select. You can have up to 5 different wallet
                taxes.
              </p>
            </div>
            {walletTaxes.length <= 0 && isAddButtonVisible && (
              <div className="ms-auto">
                <Button
                  className="btn-sm w-auto"
                  style={{ backgroundColor: "rgba(29, 100, 250, 0.3)" }}
                  onClick={handleAddWalletTax}
                >
                  Add Wallet Tax
                </Button>
              </div>
            )}
          </Stack> */}
          {taxesFormData.devTax &&
            walletTaxes.map((tax, index) => (
              <div
                key={tax.id}
                style={{
                  // background: "rgba(32, 32, 34, 1)",
                  padding: "2rem 1.5rem",
                  borderRadius: "0.5rem",
                }}
                className="mb-3"
              >
                <Row>
                  <Col sm={2}>
                    <p className="text-light">Wallet Tax {tax.id}</p>
                  </Col>
                  <Col sm={2} className="ms-auto">
                    {/* <Button
                    className="btn-sm"
                    style={{ backgroundColor: "rgba(255, 0, 0, 0.3)" }}
                    onClick={() => handleDeleteWalletTax(tax.id)}
                  >
                    Delete
                  </Button> */}
                  </Col>
                </Row>
                <Row>
                  <Input
                    Label={"Tax Name"}
                    Description={"Max 40 characters. (Not editable later)"}
                    value={tax.walletTaxname}
                    onChange={(e) =>
                      handleWalletInputChange(
                        tax.id,
                        "walletTaxname",
                        e.target.value
                      )
                    }
                    // Error={}
                  />
                  {errors[`walletTaxname${index}`] && (
                    <p style={{ color: "red" }}>
                      {errors[`walletTaxname${index}`]}
                    </p>
                  )}
                  {/* {formErrors[`walletTaxnameLength${index}`] && (
                  <p className="text-danger">
                    {formErrors[`walletTaxnameLength${index}`]}
                  </p>
                )} */}
                </Row>
                <Row className="my-3">
                  <Input
                    Label={"Recipient Address"}
                    Description={"Enter valid wallet address."}
                    value={tax.walletRecipientAddress}
                    onChange={(e) =>
                      handleWalletInputChange(
                        tax.id,
                        "walletRecipientAddress",
                        e.target.value
                      )
                    }
                  />
                  {errors[`walletRecipientAddress${index}`] && (
                    <p className="text-danger">
                      {errors[`walletRecipientAddress${index}`]}
                    </p>
                  )}
                </Row>
                {defaultExchange === true ? (
                  <Row>
                    <SwitchCheckboxInputTaxes
                      Placeholder={"0"}
                      Error={null}
                      InputDescription1={"0% (disabled) ≤ Total buy tax ≤ 25%"}
                      InputDescription2={"0% (disabled) ≤ Total sell tax ≤ 25%"}
                      InputDescription3={
                        "0% (disabled) ≤ Total transfer tax ≤ 25%"
                      }
                      label1={"Buy tax rate"}
                      label2={"Sell tax rate"}
                      label3={"Transfer tax rate"}
                      labelDescription1={
                        "Specify the exact rate of tax to be charged on buys."
                      }
                      labelDescription2={
                        "Specify the exact rate of tax to be charged on sells."
                      }
                      labelDescription3={
                        "Specify the exact rate of tax to be charged on transfers (other types of transactions)."
                      }
                      labelName1={`walletBuyRate${tax.id}`}
                      labelName2={`walletSellRate${tax.id}`}
                      labelName3={`walletTransferRate${tax.id}`}
                      toggleValue1={tax.walletBuyRate}
                      toggleValue2={tax.walletSellRate}
                      toggleValue3={tax.walletTransferRate}
                      toggleOnchange={(e) =>
                        handleWalletToggleChange(
                          tax.id,
                          e.target.name.replace(tax.id, ""),
                          e.target.checked
                        )
                      }
                      InputName1={`walletBuyInput${tax.id}`}
                      InputName2={`walletSellInput${tax.id}`}
                      InputName3={`walletTransferRateInput${tax.id}`}
                      InputValue1={tax.walletBuyInput}
                      InputValue2={tax.walletSellInput}
                      InputValue3={tax.walletTransferRateInput}
                      walletBuyError={errors[`walletBuyInput${index}`]}
                      walletSellError={errors[`walletSellInput${index}`]}
                      walletTransferError={
                        errors[`walletTransferRateInput${index}`]
                      }
                      InputOnChange={(e) =>
                        handleWalletInputChange(
                          tax.id,
                          e.target.name.replace(tax.id, ""),
                          e.target.value
                        )
                      }
                    />
                  </Row>
                ) : (
                  <Row>
                    <Input
                      Error={errors[`walletTaxRateInput${index}`]}
                      Label={"Tax rate"}
                      Description={"0% (disabled) ≤ Total tax ≤ 25%"}
                      value={tax.walletTaxRateInput}
                      onChange={(e) =>
                        handleWalletInputChange(
                          tax.id,
                          "walletTaxRateInput",
                          e.target.value
                        )
                      }
                    />
                  </Row>
                )}
              </div>
            ))}
          {/* {walletTaxes.length > 0 && walletTaxes.length < 5 && (
            <div className="ms-auto">
              <Button
                className="btn-sm w-auto mt-3"
                style={{ backgroundColor: "rgba(29, 100, 250, 0.3)" }}
                onClick={handleAddWalletTax}
              >
                Add Wallet Tax
              </Button>
            </div>
          )} */}
          {/* Wallet Taxes Section Ends Here */}

          {/* Auto-burn tax Toggle Switch Start from Here */}
          <Col sm={12}>
            <div className="my-3">
              <Col>
                {/* <Form.Check
                    style={{ color: "#F1F1F1", fontWeight: "500" }}
                    type="switch"
                    id="Auto-burn tax"
                    name="autoBurnTax"
                    checked={taxesFormData.autoBurnTax}
                    onChange={(e) => handleToggleChange(e)}
                    label={"Auto-burn tax"}
                  /> */}
                <div className="toggle-switch-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name={"autoBurnTax"}
                      id={"Auto-burn tax"}
                      checked={taxesFormData.autoBurnTax}
                      onChange={(e) => handleToggleChange(e)}
                    />
                    <span className="slider" />
                  </label>
                  <label htmlFor={"Auto-burn tax"} style={{ color: "#F1F1F1" }}>
                    {"Auto-burn tax"}
                  </label>
                </div>
                <div style={{ color: "#737373" }}>
                  The Auto-burn tax will automatically burn collected tokens in
                  the same transaction.
                </div>
              </Col>
              {taxesFormData.autoBurnTax == true && defaultExchange == true ? (
                <SwitchCheckboxInputTaxes
                  Placeholder={"0"}
                  Error={Error}
                  InputDescription={"0% (disabled) ≤ Total buy tax ≤ 25%"}
                  label1={"Buy tax rate"}
                  label2={"Sell tax rate"}
                  label3={"Transfer tax rate"}
                  labelDescription1={
                    "Specify the exact rate of tax to be charged on buys."
                  }
                  labelDescription2={
                    "Specify the exact rate of tax to be charged on sells."
                  }
                  labelDescription3={
                    "Specify the exact rate of tax to be charged on transfers (other types of transactions)."
                  }
                  labelName1={"autoBurnBuyRate"}
                  labelName2={"autoBurnSellRate"}
                  labelName3={"autoBurnTransferRate"}
                  toggleValue1={taxesFormData.autoBurnBuyRate}
                  toggleValue2={taxesFormData.autoBurnSellRate}
                  toggleValue3={taxesFormData.autoBurnTransferRate}
                  toggleOnchange={(e) => handleToggleChange(e)}
                  InputName1={"autoBurnBuyInput"}
                  InputName2={"autoBurnSellInput"}
                  InputName3={"autoBurnTransferInput"}
                  InputValue1={taxesFormData.autoBurnBuyInput}
                  InputValue2={taxesFormData.autoBurnSellInput}
                  InputValue3={taxesFormData.autoBurnTransferInput}
                  InputOnChange={(e) => {
                    handleInputChange(e);
                  }}
                  InputDescription1={"0% (disabled) ≤ Total buy tax ≤ 25%"}
                  InputDescription2={"0% (disabled) ≤ Total sell tax ≤ 25%"}
                  InputDescription3={"0% (disabled) ≤ Total transfer tax ≤ 25%"}
                  autoBurnBuyError={errors.autoBurnBuyInput}
                  autoBurnSellError={errors.autoBurnSellInput}
                  autoBurnTransferError={errors.autoBurnTransferInput}
                />
              ) : (
                taxesFormData.autoBurnTax == true && (
                  <Col md={4}>
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        className="bg-transparent mt-2 placeholderColor"
                        style={{
                          borderColor: "#3A3A3A",
                          color: "#FFF",
                        }}
                        placeholder={"Tax Rate"}
                        name="autoBurnTaxInput"
                        onChange={(e) => handleInputChange(e)}
                        value={taxesFormData.autoBurnTaxInput}
                      />
                      <p
                        style={{
                          position: "absolute",
                          top: "0.5rem",
                          right: "0.5rem",
                          color: "#737373",
                        }}
                      >
                        %
                      </p>
                    </div>
                    <div
                      style={{
                        color: `${errors.autoBurnTaxInput ? "red" : "#737373"}`,
                      }}
                      className="mt-3"
                    >
                      {"0% (disabled) ≤ Total tax ≤ 25%"}
                    </div>
                  </Col>
                )
              )}
            </div>

            {/* <TaxSwitchCheckbox
                className="titles"
                Label="Auto-burn tax"
                Description="The Auto-burn tax will automatically burn collected tokens in the same transaction."
              /> */}
          </Col>
          {/* Auto-burn tax Toggle Switch Ends Here */}

          <div className="d-flex flex-column flex-md-row align-items-center">
            <Col md={3} className="text-center mt-5 mx-auto">
              <button
                onClick={() => handlePrevious()}
                className="w-100 border-color"
                type="button"
              >
                Previous
              </button>
            </Col>
            <Col md={3} className="text-center mt-5 mx-auto">
              <Button
                onClick={() => stepUpdate()}
                className="w-100 bg-btn-color border-0"
              >
                Save & Next
              </Button>
            </Col>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Taxes;
